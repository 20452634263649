/*              */
/*   WEBFONTS   */
/*              */
/*
@font-face {
	font-family: 'Lato';
	font-style: normal;
	font-weight: 300;
	src: url('fonts/Lato-Light.eot');
	src: local('Lato Light'), local('Lato-Light'),
		 url('fonts/Lato-Light.eot?#iefix') format('embedded-opentype'),
		 url('fonts/Lato-Light.woff2') format('woff2'),
		 url('fonts/Lato-Light.woff') format('woff'),
		 url('fonts/Lato-Light.ttf') format('truetype');
}

@font-face {
	font-family: 'Lato';
	font-style: normal;
	font-weight: 400;
	src: url('fonts/Lato-Regular.eot');
	src: local('Lato Regular'), local('Lato-Regular'),
		 url('fonts/Lato-Regular.eot?#iefix') format('embedded-opentype'),
		 url('fonts/Lato-Regular.woff2') format('woff2'),
		 url('fonts/Lato-Regular.woff') format('woff'),
		 url('fonts/Lato-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'Lato';
	font-style: normal;
	font-weight: 700;
	src: url('fonts/Lato-Bold.eot');
	src: local('Lato Bold'), local('Lato-Bold'),
		 url('fonts/Lato-Bold.eot?#iefix') format('embedded-opentype'),
		 url('fonts/Lato-Bold.woff2') format('woff2'),
		 url('fonts/Lato-Bold.woff') format('woff'),
		 url('fonts/Lato-Bold.ttf') format('truetype');
}
*/
/*           */
/*   TOOLS   */
/*          */
/*               */
/*   VARIABLES   */
/*               */
/*
COLOR

primary 		C19141
secondary 		?
info 			2980B9
success 		27AE60
danger 			C0392B

black			000
grey-darker		222
grey-dark 		444
grey 			666
grey-light 		888
grey-lighter 	aaa
white 			fff

BG & BORDER

h&f 				151515
border 				DDD
form & highlight 	F5F5F5
*/
/*            */
/*   IMPORT   */
/*            */
* {
  box-sizing: border-box;
}
html {
  height: 100%;
  width: 100%;
}
body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  background-color: #fff;
  font-family: "Lato", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #888;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
}
body.no-scroll {
  overflow: hidden;
}
h1,
h2,
h3,
h4,
p,
blockquote,
cite,
figure,
ol,
ul {
  margin: 0;
  padding: 0;
}
li {
  display: block;
}
img {
  image-rendering: -moz-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}
h1,
h2,
h3,
h4,
strong,
cite,
address {
  font-size: inherit;
  font-weight: normal;
  font-style: normal;
}
p {
  padding-top: 20px;
  font-family: "Lato", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: inherit;
}
p:empty {
  display: none;
}
p:first-of-type {
  padding-top: 0;
}
p.large {
  font-size: 18px;
  line-height: 36px;
}
p.small {
  font-size: 14px;
  line-height: 28px;
}
h1,
h2 {
  font-family: "Lato", "Arial", sans-serif;
  font-size: 32px;
  font-weight: 300;
  line-height: 40px;
  color: #222;
  /*
    strong {
      font-weight: inherit;
      color: $gold_dark; // ?
    }
    */
}
h2 {
  font-size: 28px;
  line-height: 36px;
  color: #444;
}
h3 {
  font-family: "Lato", "Arial", sans-serif;
  font-size: 24px;
  font-weight: 300;
  line-height: 32px;
  color: #222;
}
strong {
  font-weight: 600;
}
img {
  display: block;
  margin: 0 auto;
}
img.preload {
  opacity: 0;
  will-change: opacity;
  -webkit-transition: opacity 300ms ease;
  -moz-transition: opacity 300ms ease;
  transition: opacity 300ms ease;
}
img.loaded {
  opacity: 1;
}
address > * {
  display: block;
}
a {
  color: #c19141;
  text-decoration: none;
  cursor: pointer;
}
a.underline {
  border-bottom: 1px solid;
}
a.icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
a.icon > * {
  display: inline-block;
  margin: 0 3px;
}
a.icon > *:nth-child(1) {
  margin-left: 0;
}
a.icon > *:nth-last-child(1) {
  margin-right: 0;
}
small {
  font-size: 14px;
  line-height: 21px;
}
*:focus {
  outline: none;
}
::-webkit-input-placeholder {
  color: #888;
}
::-moz-placeholder {
  color: #888;
}
:-ms-input-placeholder {
  color: #888;
}
.fade.ng-enter {
  opacity: 0;
  -webkit-transition: opacity 300ms ease;
  -moz-transition: opacity 300ms ease;
  transition: opacity 300ms ease;
}
.fade.ng-enter-active {
  opacity: 1;
}
.fade.ng-leave {
  opacity: 1;
  -webkit-transition: opacity 300ms ease;
  -moz-transition: opacity 300ms ease;
  transition: opacity 300ms ease;
}
.fade.ng-leave-active {
  opacity: 0;
}
.fade-content.ng-enter {
  opacity: 0;
  -webkit-transition: opacity 800ms ease;
  -moz-transition: opacity 800ms ease;
  transition: opacity 800ms ease;
}
.fade-content.ng-enter-active {
  opacity: 1;
}
.fade-content.ng-leave {
  opacity: 1;
}
.fade-content.ng-leave-active {
  opacity: 0;
}
.rollup.ng-enter {
  max-height: 0;
  opacity: 0;
  -webkit-transition: all 1200ms ease;
  -moz-transition: all 1200ms ease;
  transition: all 1200ms ease;
}
.rollup.ng-enter-active {
  max-height: 999px;
  opacity: 1;
}
.rollup.ng-leave {
  max-height: 999px;
  opacity: 1;
  -webkit-transition: all 1200ms ease;
  -moz-transition: all 1200ms ease;
  transition: all 1200ms ease;
}
.rollup.ng-leave-active {
  max-height: 0;
  opacity: 0;
}
@-moz-keyframes loader {
  50% {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    -moz-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes loader {
  50% {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    -moz-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  100% {
    opacity: 0;
  }
}
@-o-keyframes loader {
  50% {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    -moz-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  100% {
    opacity: 0;
  }
}
@keyframes loader {
  50% {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    -moz-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  100% {
    opacity: 0;
  }
}
.account-sidebar {
  background-color: #f5f5f5;
  border-radius: 4px;
}
.avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.avatar img,
.avatar span {
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: inherit;
}
.avatar span {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #fff;
  text-transform: uppercase;
}
.avatar img {
  display: block;
  opacity: 0;
  will-change: opacity;
  -webkit-transition: opacity 400ms ease;
  -moz-transition: opacity 400ms ease;
  transition: opacity 400ms ease;
}
.avatar img.loaded {
  opacity: 1;
}
.avatar-xsmall {
  width: 36px;
  height: 36px;
}
.avatar-xsmall span {
  font-size: 12px;
}
.avatar-small {
  min-width: 48px;
  width: 48px;
  height: 48px;
}
.avatar-small span {
  font-size: 16px;
}
.avatar-large {
  width: 72px;
  height: 72px;
}
.avatar-large span {
  font-size: 40px;
}
.avatar-xlarge {
  width: 96px;
  height: 96px;
}
.avatar-xlarge span {
  font-size: 40px;
}
.avatar-large {
  width: 120px;
  height: 120px;
}
.avatar-large span {
  font-size: 40px;
}
.checkbox input {
  display: block;
  margin: 0;
  padding: 0;
  width: 26px;
  height: 26px;
  background-color: #eee;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  box-sizing: border-box;
  will-change: background-color, border-color;
  -webkit-transition: background-color 400ms ease, border-color 400ms ease;
  -moz-transition: background-color 400ms ease, border-color 400ms ease;
  transition: background-color 400ms ease, border-color 400ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.checkbox input:hover {
  cursor: pointer;
}
.checkbox input:disabled {
  background-color: rgba(221,221,221,0.25);
  border-color: #ddd;
  color: #888;
}
.checkbox input:checked {
  background-color: #c19141;
  border-color: #c19141;
  background-image: url("assets/images/sprite.svg");
  background-repeat: no-repeat;
  background-size: 400px 200px;
  background-position: -260px -92px;
}
.contextual-menu {
  display: inline-block;
  position: relative;
  /*
    &-toggle {
      width: 24px;
      height: 24px;
      opacity: 0.5;
      cursor: pointer;
      will-change: opacity;
      set-transition opacity 400ms ease;
      set-sprite-clip(-376px, -88px);

      &:hover {
        opacity: 1;
      }
    }
    */
  /*
    &.is-disabled {
      pointer-events: none;

      .stack-action-button {
        &:before {
          opacity: 0.15;
        }
      }
    }
    */
}
.contextual-menu-list {
  padding: 8px 0;
  min-width: 200px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 1;
  font-family: "Lato", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  color: #888;
}
.contextual-menu-list button {
  display: block;
  padding: 10px 15px;
  background-color: #fff;
  text-align: left;
  color: inherit;
  border: none;
  cursor: pointer;
  width: 100%;
  will-change: background-color;
  -webkit-transition: background-color 400ms ease;
  -moz-transition: background-color 400ms ease;
  transition: background-color 400ms ease;
}
.contextual-menu-list button:hover {
  background-color: #f9f9f9;
  color: #c19141;
}
.contextual-menu-list button.disabled {
  color: #b3b3b3;
  pointer-events: none;
  cursor: default;
}
.contextual-menu-list .divider {
  margin: 8px 0;
  height: 1px;
  background-color: #e6e6e6;
}
.contextual-menu.open .contextual-menu-list {
  display: block;
}
.dropdown select {
  width: 100%;
  height: 100%;
  background-color: #fff;
  opacity: 0;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.dropdown-inner {
  position: relative;
}
.dropdown-inner > div {
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 11px 38px 11px 15px;
  width: 100%;
  height: 48px;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  box-sizing: border-box;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  will-change: background-color, border-color;
  position: relative;
  font-family: "Lato", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #000;
  -webkit-transition: all 400ms ease;
  -moz-transition: all 400ms ease;
  transition: all 400ms ease;
}
.dropdown-inner > div:after {
  content: "";
  width: 48px;
  height: 48px;
  border-left: 1px solid #e5e5e5;
  position: absolute;
  top: 0;
  right: 0;
  background-image: url("assets/images/sprite.svg");
  background-repeat: no-repeat;
  background-size: 400px 200px;
  background-position: -16px -152px;
  width: 48px;
  height: 48px;
}
.dropdown.has-placeholder .dropdown-inner > div {
  color: #888;
}
.dropdown.is-disabled .dropdown-inner > div {
  background-color: rgba(221,221,221,0.25);
  border-color: #ddd;
  color: #888;
  pointer-events: auto;
}
.dropdown.has-error .dropdown-inner > div {
  background-color: rgba(255,0,0,0.1);
  border-color: rgba(255,0,0,0.25);
}
.file-selector-inner {
  background-color: rgba(193,145,65,0.1);
  border: 1px solid rgba(193,145,65,0.3);
  border-radius: 4px;
  box-sizing: border-box;
  will-change: background-color;
  -webkit-transition: background-color 300ms ease;
  -moz-transition: background-color 300ms ease;
  transition: background-color 300ms ease;
}
.file-selector-inner.drag-over {
  background-color: rgba(193,145,65,0.2);
}
.file-selector-trigger {
  padding: 40px 20px;
  color: #888;
  text-align: center;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.file-selector.has-error .file-selector-inner {
  background-color: rgba(255,0,0,0.1);
  border-color: rgba(255,0,0,0.25);
}
.filters {
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  font-family: "Lato", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  color: #666;
}
.filters .group-header {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin: -1px;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #e5e5e5;
}
.filters .group-header strong {
  font-weight: inherit;
}
.filters .group-header strong.toggle {
  display: flex;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.filters .group-header strong.toggle:before {
  flex-shrink: 0;
  content: "";
  margin-right: 10px;
  background-image: url("assets/images/sprite.svg");
  background-repeat: no-repeat;
  background-size: 400px 200px;
  background-position: -384px -56px;
  width: 16px;
  height: 16px;
}
.filters .group-header strong.toggle.close:before {
  background-image: url("assets/images/sprite.svg");
  background-repeat: no-repeat;
  background-size: 400px 200px;
  background-position: -368px -56px;
  width: 16px;
  height: 16px;
}
.filters .group-header button {
  font-size: 14px;
  color: #aaa;
}
.filters .group:first-child .group-header {
  border-radius: 4px 4px 0 0;
}
.filters > section {
  background-color: transparent !important;
  padding: 20px 0;
  border-top: 2px solid #eee;
  box-sizing: border-box;
  text-align: left;
}
.filters > section:first-of-type {
  border-top: 0;
}
.filters .link-btn,
.filters a {
  display: block;
  will-change: color;
  font-family: "Lato", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #888;
  -webkit-transition: color 400ms ease;
  -moz-transition: color 400ms ease;
  transition: color 400ms ease;
}
.filters .link-btn:hover,
.filters a:hover {
  color: #666;
}
.filters .link-btn.checkbox,
.filters a.checkbox {
  display: flex;
  align-items: center;
}
.filters .link-btn.checkbox:before,
.filters a.checkbox:before {
  content: "";
  margin-right: 10px;
  width: 18px;
  height: 18px;
  background-color: #eee;
  border: 1px solid #e5e5e5;
  border-radius: 2px;
  will-change: background-color;
  -webkit-transition: background-color 400ms ease;
  -moz-transition: background-color 400ms ease;
  transition: background-color 400ms ease;
}
.filters .link-btn.active,
.filters a.active {
  color: #c19141;
}
.filters .link-btn.active:hover,
.filters a.active:hover {
  color: #c19141;
}
.filters .link-btn.active.checkbox:before,
.filters a.active.checkbox:before {
  background-color: #c19141;
  background-image: url("assets/images/sprite.svg");
  background-repeat: no-repeat;
  background-size: 400px 200px;
  background-position: -352px -56px;
  width: 16px;
  height: 16px;
}
.filters-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  text-align: left;
}
.filters-header strong,
.filters-header .link-btn,
.filters-header a {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: "Lato", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  color: #444;
}
.filters-header strong.toggle {
  display: flex;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.filters-header strong.toggle:before {
  flex-shrink: 0;
  content: "";
  margin-right: 10px;
  background-image: url("assets/images/sprite.svg");
  background-repeat: no-repeat;
  background-size: 400px 200px;
  background-position: -384px -56px;
  width: 16px;
  height: 16px;
}
.filters-header strong.toggle.close:before {
  background-image: url("assets/images/sprite.svg");
  background-repeat: no-repeat;
  background-size: 400px 200px;
  background-position: -368px -56px;
  width: 16px;
  height: 16px;
}
.filters-header a {
  margin-left: 20px;
  color: #aaa;
}
.filters-header a:hover {
  color: #888;
}
.filters-body {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  width: 100%;
  text-align: left;
}
.filters-body > a {
  padding-top: 10px;
  width: 100%;
  color: #aaa;
}
.filters-body > a:hover {
  color: #888;
}
.filters-links li {
  padding: 5px 20px;
}
.filters-links.minimize {
  overflow: hidden;
  max-height: 150px;
}
.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 40px;
  width: 100%;
  background-color: #151515;
  box-sizing: border-box;
  text-align: center;
  position: relative;
  z-index: 2;
}
.footer > small {
  color: #444;
}
.footer a.logo {
  overflow: hidden;
  display: block;
  margin-bottom: 20px;
  text-indent: -9999px;
  background-image: url("assets/images/sprite.svg");
  background-repeat: no-repeat;
  background-size: 308px 154px;
  background-position: 0 0;
  width: 120.12px;
  height: 20.02px;
}
.footer a.phone,
.footer a.email {
  display: block;
  padding: 5px 0 0 0;
  font-family: "Lato", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #aaa;
}
.footer a.link {
  padding: 5px 0 0 0;
  will-change: color;
  font-family: "Lato", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #666;
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  transition: color 0.3s ease;
}
.footer a.link:hover {
  color: #888;
}
.footer a.icon {
  display: inline-block;
  margin-right: 20px;
  text-indent: -9999px;
}
.footer a.icon-twitter {
  background-image: url("assets/images/sprite.svg");
  background-repeat: no-repeat;
  background-size: 400px 200px;
  background-position: -344px -32px;
  width: 20px;
  height: 20px;
}
.footer a.icon-linkedin {
  background-image: url("assets/images/sprite.svg");
  background-repeat: no-repeat;
  background-size: 400px 200px;
  background-position: -368px -32px;
  width: 20px;
  height: 20px;
}
.footer a.icon-instagram {
  background-image: url("assets/images/sprite.svg");
  background-repeat: no-repeat;
  background-size: 400px 200px;
  background-position: -344px -32px;
  width: 20px;
  height: 20px;
}
.footer .inner {
  display: flex;
  justify-content: space-between;
  padding: 40px 40px 80px 40px;
  width: 100%;
  max-width: 1280px;
  box-sizing: border-box;
}
.footer-details {
  text-align: left;
}
.footer-details > div {
  padding-top: 40px;
}
.footer-links {
  display: flex;
}
.footer-links > div {
  display: flex;
  flex-direction: column;
  padding-left: 40px;
  width: 240px;
  box-sizing: border-box;
  text-align: left;
}
.footer-links > div:last-child {
  width: auto;
}
.footer-links strong {
  display: block;
  padding-bottom: 10px;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: "Lato", "Arial", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #ddd;
}
.footer-links strong:after {
  content: "";
  display: block;
  margin-top: 10px;
  width: 32px;
  height: 2px;
  background-color: #af8339;
}
.header {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 997;
}
.header a.txt-uppercase,
.header a.icon {
  margin: 0 20px;
}
.header a.txt-uppercase {
  will-change: color;
  font-family: "Lato", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #aaa;
  -webkit-transition: color 300ms ease;
  -moz-transition: color 300ms ease;
  transition: color 300ms ease;
}
.header a.txt-uppercase:focus,
.header a.txt-uppercase:hover,
.header a.txt-uppercase.active {
  color: #fff;
}
.header a.icon {
  position: relative;
}
.header a.icon span {
  margin: 0;
  opacity: 0.5;
  will-change: opacity;
  -webkit-transition: opacity 300ms ease;
  -moz-transition: opacity 300ms ease;
  transition: opacity 300ms ease;
}
.header a.icon:focus span,
.header a.icon:hover span {
  opacity: 1;
}
.header a.icon .badge {
  position: absolute;
  top: -8px;
  left: 20px;
  z-index: 1;
}
.header-top,
.header-bottom {
  display: flex;
  justify-content: center;
}
.header-top-inner,
.header-bottom-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
}
.header-top-inner {
  padding: 10px 40px 0 40px;
  font-family: "Lato", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #fff;
}
.rates > span:not(:last-child) {
  margin-right: 3px;
}
.header-bottom {
  width: 100%;
  will-change: background-color;
  -webkit-transition: background-color 300ms linear;
  -moz-transition: background-color 300ms linear;
  transition: background-color 300ms linear;
}
.header-bottom-inner {
  padding: 30px 40px;
}
.header-bottom.sticky {
  position: fixed;
  top: 0;
  left: 0;
}
.header-bg {
  background-color: #151515;
}
.header-bg .header-bottom {
  background-color: #151515;
  -webkit-transition: none;
  -moz-transition: none;
  transition: none;
}
.header-margin {
  margin-top: 140px;
}
.header-panel {
  opacity: 0;
  will-change: opacity;
  position: absolute;
  top: -1000%;
  right: 0;
  z-index: 1;
  -webkit-transition: opacity 300ms ease;
  -moz-transition: opacity 300ms ease;
  transition: opacity 300ms ease;
}
.header-panel-wrapper {
  margin-left: 20px;
  position: relative;
}
.header-panel-wrapper:hover .header-panel {
  top: 100%;
  opacity: 1;
}
.header .logo {
  text-transform: uppercase;
  letter-spacing: 0.4px;
  font-family: "Lato", "Arial", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: #aaa;
}
.header .logo a {
  overflow: hidden;
  display: block;
  margin-bottom: 4px;
  text-indent: -9999px;
  background-image: url("assets/images/sprite.svg");
  background-repeat: no-repeat;
  background-size: 400px 200px;
  background-position: 0 0;
  width: 156px;
  height: 26px;
}
.header .menu {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.header .button {
  margin-left: 20px;
}
.header .spacer {
  display: inline-block;
  margin: 0 20px;
  width: 1px;
  height: 32px;
  background-color: #2c2c2c;
}
.image-editor {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
  background: rgba(68,68,68,0.8);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  /*
    &-notification {
      padding: 15px;
      text-align: center;
      set-font($lato, 14px, 400, 21px, $white);

      // strong font-size: 16px ?

      p {
        padding-top: 5px;
        font-size: inherit;
        line-height: inherit;
        color: alpha($white, 80%);

        &:first-of-type {
          padding-top: 5px;
        }
      }
    }

    &-form {
      padding: 5px 0;
      text-align: left;

      & > li {
        display: table;
        width: 100%;
        max-width: 100%;
        table-layout: fixed;

        & > div {
          display: table-cell;
          padding: 15px;
          width: 100%;
          box-sizing: border-box;
          vertical-align: top;

          &:nth-child(1):nth-last-child(2),
          &:nth-child(2):nth-last-child(1) {
            width: 50%;
          }

          &:nth-child(1):nth-last-child(4),
          &:nth-child(2):nth-last-child(3),
          &:nth-child(3):nth-last-child(2),
          &:nth-child(4):nth-last-child(1) {
            width: 25%;
          }
        }
      }
    }
    */
}
.image-editor-window {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: 0 0 20px 0 rgba(21,21,21,0.25);
  text-align: center;
}
.image-editor-body {
  overflow-y: auto;
}
.image-editor-window-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  height: 60px;
  background-color: #151515;
  border-radius: 4px 4px 0 0;
}
.image-editor-window-header .icon {
  background-color: transparent;
  border: none;
  cursor: pointer;
  width: 16px;
  height: 16px;
}
.image-editor-window-header .icon>span {
  display: inline-block;
}
.image-editor-window-header strong,
.image-editor-window-header a {
  margin: 0 10px;
}
.image-editor-window-header strong {
  letter-spacing: 2px;
  text-transform: uppercase;
  font-family: "Lato", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  color: #fff;
}
.image-editor-window-body {
  padding: 30px;
}
.image-editor-window-footer {
  height: 60px;
}
.image-editor-window-footer .button {
  border-radius: 0 0 4px 4px;
}
.image-editor-area {
  flex: 1;
  padding: 40px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.photo-crop {
  user-select: none;
  cursor: move;
  position: relative;
}
.photo-crop .dimension {
  overflow: hidden;
  box-shadow: 0 0 0 100vw rgba(255,255,255,0.7);
  border: 1px solid rgba(0,0,0,0.25);
  background-color: transparent;
  pointer-events: none;
  box-sizing: border-box;
  position: relative;
  z-index: 2;
}
.photo-crop img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
@media screen and (min-height: 1080px) {
  .image-editor-area {
    padding: 100px;
  }
}
.image-selector-inner {
  background-color: rgba(193,145,65,0.1);
  border: 1px solid rgba(193,145,65,0.3);
  border-radius: 4px;
  box-sizing: border-box;
  will-change: background-color;
  -webkit-transition: background-color 200ms ease;
  -moz-transition: background-color 200ms ease;
  transition: background-color 200ms ease;
}
.image-selector-inner.drag-over {
  background-color: rgba(193,145,65,0.2);
}
.image-selector-trigger {
  padding: 40px 20px;
  color: #888;
  text-align: center;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.image-selector.has-error .image-selector-inner {
  background-color: rgba(255,0,0,0.1);
  border-color: rgba(255,0,0,0.25);
}
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 48px;
}
.loader span {
  margin: 0 4px;
  width: 8px;
  height: 8px;
  background-color: #ddd;
  border-radius: 50%;
  -webkit-transform: scale3d(0, 0, 0);
  -moz-transform: scale3d(0, 0, 0);
  transform: scale3d(0, 0, 0);
  -webkit-animation: loader 1000ms infinite;
  -moz-animation: loader 1000ms infinite;
  animation: loader 1000ms infinite;
}
.loader span:nth-child(2) {
  -webkit-animation: loader 1000ms infinite 300ms;
  -moz-animation: loader 1000ms infinite 300ms;
  animation: loader 1000ms infinite 300ms;
}
.loader span:nth-child(3) {
  -webkit-animation: loader 1000ms infinite 600ms;
  -moz-animation: loader 1000ms infinite 600ms;
  animation: loader 1000ms infinite 600ms;
}
.notification {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 30px;
  width: 100%;
  box-sizing: border-box;
  text-align: left;
  word-wrap: break-word;
  opacity: 0;
  pointer-events: none;
  will-change: opacity;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 998;
  font-family: "Lato", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #fff;
  -webkit-transition: opacity 400ms ease;
  -moz-transition: opacity 400ms ease;
  transition: opacity 400ms ease;
}
.notification div {
  flex: 1;
}
.notification.show {
  display: flex;
  opacity: 0.98;
}
.notification-close {
  margin-left: 30px;
  background-image: url("assets/images/sprite.svg");
  background-repeat: no-repeat;
  background-size: 400px 200px;
  background-position: -312px 0;
  width: 16px;
  height: 24px;
}
.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
  background: rgba(68,68,68,0.8);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
}
.modal-window {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 360px;
  background-color: #fff;
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: 0 0 20px 0 rgba(21,21,21,0.25);
  text-align: center;
}
.modal-window-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  height: 60px;
  background-color: #151515;
  border-radius: 4px 4px 0 0;
}
.modal-window-header strong,
.modal-window-header a {
  margin: 0 10px;
}
.modal-window-header strong {
  letter-spacing: 2px;
  text-transform: uppercase;
  font-family: "Lato", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  color: #fff;
}
.modal-window-body {
  padding: 30px;
}
.modal-window-footer {
  height: 60px;
}
.modal-window-footer .button {
  border-radius: 4px;
}
.modal-window-footer .button:first-child {
  margin-right: 16px;
}
.modal-notification {
  padding: 15px;
  text-align: center;
  font-family: "Lato", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #fff;
}
.modal-notification p {
  padding-top: 5px;
  font-size: inherit;
  line-height: inherit;
  color: rgba(255,255,255,0.8);
}
.modal-notification p:first-of-type {
  padding-top: 5px;
}
.modal-form {
  padding: 5px 0;
  text-align: left;
}
.modal-form > li {
  display: table;
  width: 100%;
  max-width: 100%;
  table-layout: fixed;
  /*
        &:first-child {
          padding-top: 0;
        }
        */
}
.modal-form > li > div {
  display: table-cell;
  padding: 15px;
  width: 100%;
  box-sizing: border-box;
  vertical-align: top;
}
.modal-form > li > div:nth-child(1):nth-last-child(2),
.modal-form > li > div:nth-child(2):nth-last-child(1) {
  width: 50%;
}
.modal-form > li > div:nth-child(1):nth-last-child(4),
.modal-form > li > div:nth-child(2):nth-last-child(3),
.modal-form > li > div:nth-child(3):nth-last-child(2),
.modal-form > li > div:nth-child(4):nth-last-child(1) {
  width: 25%;
}
.modal-login .forgot-password {
  padding-top: 5px;
  text-align: right;
}
.modal-signup .modal-window {
  max-width: 640px;
}
.modal-order .modal-window {
  max-width: 640px;
}
.modal-image-editor .modal-window {
  max-width: unset;
}
.modal-review-offer .modal-window {
  max-width: unset;
}
.modal-review-offer .modal-window-body {
  max-height: 500px;
  overflow-y: scroll;
}
.modal-review-offer .column {
  display: flex;
  width: 100%;
  padding-bottom: 20px;
}
.modal-review-offer .column-left {
  flex: 1;
  padding: 0 40px;
}
.modal-review-offer .column-right {
  width: 280px;
  min-width: 280px;
  margin-right: 40px;
  text-align: left;
}
.modal-review-offer .column-right > div {
  margin-top: 20px;
}
.modal-review-counter-offer .modal-window {
  max-width: unset;
}
.modal-review-counter-offer .modal-window-body {
  max-height: 500px;
  overflow-y: scroll;
}
.modal-review-counter-offer .column {
  display: flex;
  width: 100%;
  padding-bottom: 20px;
}
.modal-review-counter-offer .column-left {
  flex: 1;
  padding: 0 40px;
}
.modal-review-counter-offer .column-right {
  width: 280px;
  margin-right: 40px;
  text-align: left;
}
.modal-review-counter-offer .column-right > div {
  margin-top: 20px;
}
.nav-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  width: 100%;
  box-shadow: inset 0 -1px 0 0 #e5e5e5;
  box-sizing: border-box;
  position: relative;
}
.nav-bar:before,
.nav-bar:after {
  content: "";
  width: 20px;
  background-color: #fff;
  box-shadow: inset 0 -1px 0 0 #e5e5e5;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 3;
}
.nav-bar:before {
  left: 20px;
  background: linear-gradient(to right, #fff 0%, transparent 100%);
}
.nav-bar:after {
  right: 20px;
  background: linear-gradient(to left, #fff 0%, transparent 100%);
}
.nav-bar-container {
  overflow: hidden;
  height: 50px;
  position: relative;
  z-index: 1;
}
.nav-bar-list {
  overflow-x: scroll;
  overflow-y: hidden;
  display: block;
  height: 65px;
  position: relative;
  z-index: 1;
  -webkit-overflow-scrolling: touch;
}
.nav-bar ul {
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
  position: relative;
}
.nav-bar li {
  display: inline-block;
  padding: 0 30px;
}
.nav-bar button {
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  display: block;
  height: 50px;
  white-space: nowrap;
  will-change: color;
  position: relative;
  font-family: "Open Sans", "Arial", sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  color: #888;
  -webkit-transition: color 400ms ease;
  -moz-transition: color 400ms ease;
  transition: color 400ms ease;
}
.nav-bar button:after {
  content: "";
  width: 100%;
  height: 2px;
  background-color: #af8339;
  opacity: 0;
  will-change: opacity;
  position: absolute;
  left: 0;
  bottom: 0px;
  z-index: 2;
  -webkit-transition: opacity 400ms ease;
  -moz-transition: opacity 400ms ease;
  transition: opacity 400ms ease;
}
.nav-bar button:hover {
  color: #9c7433;
}
.nav-bar button.active {
  color: #9c7433;
}
.nav-bar button.active:after {
  opacity: 1;
}
.notification {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 30px;
  width: 100%;
  box-sizing: border-box;
  text-align: left;
  word-wrap: break-word;
  opacity: 0;
  pointer-events: none;
  will-change: opacity;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 998;
  font-family: "Lato", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  color: #fff;
  -webkit-transition: opacity 400ms ease;
  -moz-transition: opacity 400ms ease;
  transition: opacity 400ms ease;
}
.notification div {
  flex: 1;
}
.notification.show {
  display: flex;
  opacity: 0.98;
}
.notification-close {
  margin-left: 30px;
  pointer-events: auto;
  background-image: url("assets/images/sprite.svg");
  background-repeat: no-repeat;
  background-size: 400px 200px;
  background-position: -312px 0;
  width: 16px;
  height: 24px;
}
.offer-card {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.offer-card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px 4px 0 0;
  margin: 0;
}
.offer-card-body {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
  font-family: "Lato", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  color: #888;
}
.offer-card-footer {
  display: flex;
  justify-content: flex-end;
  margin: -1px 0;
  background-color: #eee;
  border: 1px solid #ddd;
  border-radius: 0 0 4px 4px;
}
.offer-card-footer a {
  display: block;
  width: 48px;
  height: 48px;
  background-color: transparent;
  border-left: 1px solid #ddd;
}
.offer-card .toggle-group {
  display: none;
}
.offer-card .toggle-group.show {
  display: block;
}
.offer-card .row {
  display: flex;
  margin-top: -1px;
  width: 100%;
}
.offer-card .row-main:nth-child(odd) {
  background-color: #f9f9f9;
}
.offer-card .row-header {
  background-color: #e5e5e5;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: "Lato", "Arial", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  color: #888;
}
.offer-card .row-header > div {
  padding: 10px 20px;
  background-color: #e5e5e5;
  /**/
  /**/
}
.offer-card .row-form input {
  text-align: right;
}
.offer-card .col {
  display: flex;
  align-self: stretch;
  border: 1px solid #ddd;
  box-sizing: border-box;
}
.offer-card .cols {
  display: flex;
  flex-direction: column;
}
.offer-card .cols .row {
  align-items: center;
  justify-content: center;
}
.offer-card .cols .row:first-child {
  margin: 0;
}
.offer-card .col-product {
  flex: 1;
  flex-direction: column;
  justify-content: center;
  text-align: left;
}
.offer-card .col-qty,
.offer-card .col-msrp,
.offer-card .col-wholesale {
  justify-content: center;
  align-items: center;
  margin-left: -1px;
}
.offer-card .col-details {
  align-items: center;
  margin-left: -1px;
  padding: 20px;
  width: 200px;
  background-color: #fafafa;
}
.offer-card .col-product,
.offer-card .col-qty,
.offer-card .col-msrp,
.offer-card .col-wholesale {
  padding: 20px;
}
.offer-card .col-qty {
  width: 140px;
}
.offer-card .col-msrp,
.offer-card .col-wholesale {
  width: 180px;
}
.offer-card .col-toggle {
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  border-left: 0;
}
.offer-card-old {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-align: left;
  /*
    <div class="offer-card-header">
        <div><strong>User</strong> from <strong>Company</strong> sent you a new offer.</div>
        <div><span>Today at 12:00:00</span><span>New</span></div>
      </div>
      <a href="#">Offer history</a><!-- ng-if="offer.current_version > 1" -->
      <!--<a ng-if="offer.has_message" href="#">Message</a>-->
      <strong>Stack action</strong>
    </div>
    */
}
.offer-card-old-header {
  display: flex;
  align-items: center;
  padding: 15px;
  width: 100%;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 4px 4px 0 0;
  box-sizing: border-box;
  font-family: "Lato", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #888;
}
.offer-card-old-header .avatar {
  display: inline-block;
  margin-right: 15px;
  width: 48px;
  height: 48px;
  background-color: #fff;
  border-radius: 4px;
  position: relative;
}
.offer-card-old-header .avatar:after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 4px;
  box-shadow: inset 0 0 0 1px rgba(0,0,0,0.14);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
.offer-card-old-header .avatar img {
  overflow: hidden;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  opacity: 0;
  will-change: opacity;
  -webkit-transition: opacity 400ms ease;
  -moz-transition: opacity 400ms ease;
  transition: opacity 400ms ease;
}
.offer-card-old-header .avatar img.loaded {
  opacity: 1;
}
.offer-card-old-header .offer-info {
  flex: 1;
}
.offer-card-old-header strong {
  font-weight: 400;
  color: #444;
}
.offer-card-old-header small {
  color: #aaa;
}
.offer-card-old-header a {
  color: #c19141;
}
.offer-card-old-body {
  flex: 1;
  padding: 20px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ddd;
  border-top: 0;
  box-sizing: border-box;
}
.offer-card-old-body > a {
  color: #444;
}
.offer-card-old-body > div {
  padding-top: 10px;
  font-size: 14px;
  line-height: 21px;
}
.offer-card-old-body > div small {
  font-size: 12px;
  font-weight: 300;
  line-height: 18px;
}
.offer-card-old-body > div a {
  display: block;
  color: #444;
}
.offer-card-old-footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
  width: 100%;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 0 0 4px 4px;
  box-sizing: border-box;
}
.phonefield select {
  width: 100%;
  height: 100%;
  background-color: #fff;
  opacity: 0;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.phonefield input {
  display: block;
  margin: 0;
  padding: 10px 15px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0 4px 4px 0;
  box-sizing: border-box;
  will-change: background-color, border-color;
  font-family: "Lato", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #222;
  -webkit-transition: all 400ms ease;
  -moz-transition: all 400ms ease;
  transition: all 400ms ease;
}
.phonefield input:disabled {
  color: #888;
}
.phonefield-dropdown-inner {
  position: relative;
}
.phonefield-dropdown-inner > div {
  overflow: hidden;
  display: block;
  margin: 0;
  width: 100%;
  height: 48px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-right: 0;
  border-radius: 4px 0 0 4px;
  box-sizing: border-box;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  will-change: background-color, border-color;
  position: relative;
  font-family: "Lato", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #000;
  -webkit-transition: all 400ms ease;
  -moz-transition: all 400ms ease;
  transition: all 400ms ease;
}
.phonefield-dropdown-inner > div:before {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  -moz-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
}
.phonefield.has-focus input {
  border-color: #aaa;
}
.phonefield.has-focus .phonefield-dropdown-inner > div {
  border-color: #aaa;
}
.phonefield.has-error input {
  border-color: #e6978f;
}
.service-packages {
  display: flex;
  justify-content: center;
  align-items: stretch;
}
.service-packages > li {
  width: 100%;
  box-sizing: border-box;
}
.service-packages > li:first-of-type,
.service-packages > li:last-of-type {
  padding: 20px 0;
}
.service-packages > li:first-of-type .card-header {
  border-radius: 4px 0 0 0;
}
.service-packages > li:first-of-type .card-body {
  border-right: 0;
}
.service-packages > li:first-of-type .card-footer a {
  border-radius: 0 0 0 4px;
}
.service-packages > li:last-of-type .card-header {
  border-radius: 0 4px 0 0;
}
.service-packages > li:last-of-type .card-body {
  border-left: 0;
}
.service-packages > li:last-of-type .card-footer a {
  border-radius: 0 0 4px 0;
}
.service-packages > li:nth-child(2) {
  position: relative;
  z-index: 2;
}
.service-packages > li:nth-child(2) .card {
  height: 100%;
  box-shadow: 0 0 40px 0 rgba(0,0,0,0.3);
}
.service-packages > li:nth-child(2) .card-header {
  background-color: #575757;
}
.service-packages .card {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.service-packages .card-header {
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  background-color: #444;
  box-shadow: inset 0 0 0 1px rgba(0,0,0,0.1);
  border-radius: 4px 4px 0 0;
  box-sizing: border-box;
}
.service-packages .card-header strong {
  color: #fff;
}
.service-packages .card-header span {
  padding-top: 20px;
  font-family: "Montserrat", "Arial", sans-serif;
  font-size: 48px;
  font-weight: 200;
  line-height: normal;
  color: #fff;
}
.service-packages .card-header small {
  color: #888;
}
.service-packages .card-body {
  flex: 1;
  padding: 20px 40px 40px 40px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ddd;
  border-top: 0;
  border-bottom: 0;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 21px;
}
.service-packages .card-footer a {
  border-radius: 0 0 4px 4px;
}
.service-packages .features {
  padding: 20px 0 0 0;
}
.service-packages .features li {
  padding: 10px 0 0 0;
}
.service-packages .features li:after {
  content: "";
  display: block;
  margin: 10px auto 0 auto;
  width: 64px;
  height: 1px;
  background-color: #ddd;
}
.service-packages .features li:last-child:after {
  content: none;
}
.sidebar {
  padding-left: 40px;
  height: 100%;
  box-sizing: border-box;
}
.sidebar > section {
  padding: 20px 0;
  box-sizing: border-box;
  text-align: left;
}
.sidebar > section:first-of-type {
  border-top: 0;
}
.sidebar a {
  display: block;
  will-change: color;
  font-family: "Lato", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #888;
  -webkit-transition: color 400ms ease;
  -moz-transition: color 400ms ease;
  transition: color 400ms ease;
}
.sidebar a:hover {
  color: #c19141;
}
.sidebar a.checkbox {
  display: flex;
  align-items: center;
}
.sidebar a.checkbox:before {
  content: "";
  margin-right: 10px;
  width: 16px;
  height: 16px;
  background-color: #ddd;
  border-radius: 2px;
  will-change: background-color;
  -webkit-transition: background-color 400ms ease;
  -moz-transition: background-color 400ms ease;
  transition: background-color 400ms ease;
}
.sidebar a.active {
  color: #c19141;
}
.sidebar a.active:hover {
  color: #c19141;
}
.sidebar a.active.checkbox:before {
  background-color: #c19141;
  background-image: url("assets/images/sprite.svg");
  background-repeat: no-repeat;
  background-size: 400px 200px;
  background-position: -352px -56px;
  width: 16px;
  height: 16px;
}
.sidebar-header {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  width: 100%;
  text-align: left;
}
.sidebar-header strong,
.sidebar-header a {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: "Lato", "Arial", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #222;
}
.sidebar-header strong.toggle {
  display: flex;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.sidebar-header strong.toggle:before {
  flex-shrink: 0;
  content: "";
  margin-right: 10px;
  background-image: url("assets/images/sprite.svg");
  background-repeat: no-repeat;
  background-size: 400px 200px;
  background-position: -384px -56px;
  width: 16px;
  height: 16px;
}
.sidebar-header strong.toggle.close:before {
  background-image: url("assets/images/sprite.svg");
  background-repeat: no-repeat;
  background-size: 400px 200px;
  background-position: -368px -56px;
  width: 16px;
  height: 16px;
}
.sidebar-header a {
  margin-left: 20px;
  color: #aaa;
}
.sidebar-header a:hover {
  color: #888;
}
.sidebar-header + .sidebar-body {
  padding-top: 5px;
}
.sidebar-body {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;
}
.sidebar-body > a {
  padding-top: 10px;
  width: 100%;
  color: #aaa;
}
.sidebar-body > a:hover {
  color: #888;
}
.sidebar-links li {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  width: 100%;
  text-align: left;
}
.sidebar-links li a {
  flex: 1;
}
.sidebar-links li small {
  line-height: 20px;
  color: #c19141;
}
.sidebar-links.minimize {
  overflow: hidden;
  max-height: 150px;
}
.slider-wrapper {
  width: 100%;
  height: 18px;
  box-sizing: border-box;
  position: relative;
}
.slider-wrapper:before {
  content: "";
  width: 100%;
  height: 6px;
  background-color: #ddd;
  border-radius: 3px;
  position: absolute;
  top: 6px;
  left: 0;
}
.slider-wrapper span {
  width: 18px;
  height: 18px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 1px 4px 0 rgba(0,0,0,0.5);
  cursor: pointer;
  position: absolute;
  top: 0;
  z-index: 3;
}
.slider-wrapper span.min {
  left: 0%;
}
.slider-wrapper span.max {
  left: calc(100% - 20px);
}
.slider-wrapper div {
  content: "";
  width: 100%;
  height: 6px;
  background-color: #c19141;
  border-radius: 3px;
  position: absolute;
  top: 6px;
  left: 0;
  z-index: 2;
}
.step-bar {
  display: flex;
  justify-content: center;
  padding: 40px 0;
  background-color: #f9f9f9;
  box-shadow: inset 0 -1px 0 0 #eee;
  text-align: center;
  font-family: "Lato", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  color: #aaa;
}
.step-bar div {
  padding: 0 40px;
  width: 100%;
  max-width: 320px;
  box-sizing: border-box;
}
.step-bar div.done strong:before,
.step-bar div.active strong:before {
  border: 0;
  background-image: url("assets/images/sprite.svg");
  background-repeat: no-repeat;
  background-size: 400px 200px;
  background-position: -256px -88px;
}
.step-bar div.done strong:before {
  background-color: #ccc;
}
.step-bar div.active strong {
  color: #444;
}
.step-bar div.active strong:before {
  background-color: #c19141;
}
.step-bar strong:before {
  content: "";
  display: block;
  margin: 0 auto 10px auto;
  width: 32px;
  height: 32px;
  border: 2px solid #ccc;
  border-radius: 50%;
  box-sizing: border-box;
}
.switch input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  -webkit-apparance: none;
  -moz-apparance: none;
  apparance: none;
}
.switch span {
  margin-left: 10px;
  font-family: "Lato", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  color: #000;
}
.switch-inner {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}
.switch-trigger {
  width: 58px;
  height: 34px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
}
.switch-trigger-dragger {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  cursor: pointer;
  will-change: background-color, left;
  position: absolute;
  top: 50%;
  z-index: 2;
  -webkit-transform: translate3d(0, -50%, 0);
  -moz-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0);
  -webkit-transition: background-color 200ms ease, left 200ms ease;
  -moz-transition: background-color 200ms ease, left 200ms ease;
  transition: background-color 200ms ease, left 200ms ease;
}
.switch.is-on .switch-trigger-dragger {
  left: 50%;
  background-color: #27ae60;
}
.switch.is-off .switch-trigger-dragger {
  left: 5px;
  background-color: #c0392b;
}
.tab-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  background-color: #f9f9f9;
  box-shadow: inset 0 -1px 0 0 #eee;
  font-family: "Lato", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 60px;
  color: #aaa;
}
.tab-bar a {
  margin: 0 20px;
  padding: 0 20px;
  color: inherit;
  will-change: color;
  position: relative;
  -webkit-transition: color 300ms ease;
  -moz-transition: color 300ms ease;
  transition: color 300ms ease;
}
.tab-bar a:after {
  content: "";
  width: 100%;
  height: 3px;
  background-color: #c19141;
  opacity: 0;
  will-change: opacity;
  position: absolute;
  left: 0;
  bottom: 0px;
  z-index: 2;
  -webkit-transition: opacity 300ms ease;
  -moz-transition: opacity 300ms ease;
  transition: opacity 300ms ease;
}
.tab-bar a:hover {
  color: #444;
}
.tab-bar a.active {
  color: #444;
}
.tab-bar a.active:after {
  opacity: 1;
}
.team-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.team-grid-member {
  padding: 20px;
  width: 320px;
  box-sizing: border-box;
  text-align: center;
}
.team-grid-member strong {
  display: block;
  color: #444;
}
.team-grid-member a {
  display: block;
  margin-top: 10px;
  word-wrap: break-word;
}
.team-grid-member .avatar {
  margin: 0 auto 20px auto;
}
.textbox textarea {
  display: block;
  margin: 0;
  padding: 11px 15px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
  resize: none;
  will-change: background-color, border-color;
  font-family: "Lato", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #000;
  -webkit-transition: all 400ms ease;
  -moz-transition: all 400ms ease;
  transition: all 400ms ease;
}
.textbox textarea:disabled {
  background-color: rgba(221,221,221,0.25);
  border-color: #ddd;
  color: #888;
}
.textbox.has-error textarea {
  background-color: rgba(255,0,0,0.1);
  border-color: rgba(255,0,0,0.25);
}
.textbox.has-focus textarea {
  background-color: rgba(41,128,185,0.1);
  border-color: rgba(41,128,185,0.25);
}
.textfield input {
  min-width: 50px;
  display: block;
  margin: 0;
  padding: 10px 15px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
  will-change: background-color, border-color;
  font-family: "Lato", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #222;
  -webkit-transition: all 400ms ease;
  -moz-transition: all 400ms ease;
  transition: all 400ms ease;
}
.textfield input:disabled {
  color: #888;
}
.textfield.has-focus input {
  border-color: #aaa;
}
.textfield.has-error input {
  border-color: #e6978f;
}
.toggle input {
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  border: 0;
  opacity: 0;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.toggle-inner {
  display: flex;
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
  will-change: border-color;
  font-family: "Lato", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #444;
}
.toggle-inner div {
  width: 100%;
  padding: 10px 15px;
  border: 1px solid #ddd;
  box-sizing: border-box;
  text-align: center;
  will-change: background-color, border-color;
  position: relative;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}
.toggle-inner div:not(:first-child) {
  margin-left: -1px;
}
.toggle-inner div:first-child {
  border-radius: 4px 0 0 4px;
}
.toggle-inner div:last-child {
  border-radius: 0 4px 4px 0;
}
.toggle-inner div.selected {
  background-color: #ddd;
}
.toggle.has-focus .toggle-inner div {
  border-color: #ccc;
}
.toggle.has-error .toggle-inner div {
  border-color: #e6978f;
}
.view-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 996;
  -webkit-transition: opacity 200ms ease;
  -moz-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
}
.view-loader span {
  margin: 0 4px;
  width: 8px;
  height: 8px;
  background-color: #af8339;
  border-radius: 50%;
  -webkit-transform: scale3d(0, 0, 0);
  -moz-transform: scale3d(0, 0, 0);
  transform: scale3d(0, 0, 0);
  -webkit-animation: loader 1000ms infinite;
  -moz-animation: loader 1000ms infinite;
  animation: loader 1000ms infinite;
}
.view-loader span:nth-child(2) {
  -webkit-animation: loader 1000ms infinite 300ms;
  -moz-animation: loader 1000ms infinite 300ms;
  animation: loader 1000ms infinite 300ms;
}
.view-loader span:nth-child(3) {
  -webkit-animation: loader 1000ms infinite 600ms;
  -moz-animation: loader 1000ms infinite 600ms;
  animation: loader 1000ms infinite 600ms;
}
.view-loader.show {
  opacity: 1;
  pointer-events: auto;
}
.app {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
.app-body {
  flex: 1;
}
.badge {
  display: inline-block;
  padding: 0 4px;
  min-width: 16px;
  background-color: #999;
  border-radius: 4px;
  box-sizing: border-box;
  text-align: center;
  white-space: nowrap;
  font-family: "Open Sans", "Arial", sans-serif;
  font-size: 11px;
  font-weight: 600;
  line-height: 16px;
  color: #fff;
}
.badge-pill {
  border-radius: 8px;
}
.badge-large {
  padding: 5px 8px;
  background-color: #aaa;
  font-size: 12px;
}
.badge-success {
  background-color: #27ae60;
}
.brand-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 20px;
}
.brand-grid > div {
  padding: 20px;
  width: 100%;
  max-width: 33.33%;
  box-sizing: border-box;
}
.brand-card {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.brand-card > div:first-child {
  border-radius: 4px 4px 0 0;
}
.brand-card > div:last-child {
  border-radius: 0 0 4px 4px;
}
.brand-card > div:first-child:last-child {
  border-radius: 4px;
}
.brand-card-body {
  flex: 1;
  margin: -1px;
  padding: 40px 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
}
.brand-card-body > * {
  display: block;
}
.brand-card-body strong {
  color: #666;
}
.brand-card-footer {
  display: flex;
  justify-content: flex-end;
  margin: -1px;
  background-color: #eee;
  border: 1px solid #ddd;
  border-radius: 4px;
}
.brand-card-footer a {
  width: 48px;
  height: 48px;
  background-color: transparent;
  border-left: 1px solid #ddd;
}
.brand-new-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  height: 100%;
  background-color: rgba(193,145,65,0.3);
  border: 1px solid rgba(193,145,65,0.3);
  border-radius: 4px;
  box-sizing: border-box;
  color: rgba(0,0,0,0.4);
  text-align: center;
  cursor: pointer;
}
.brand-new-card .icon-64-plus {
  margin-bottom: 20px;
  opacity: 0.3;
}
.old-brand-card {
  width: 100%;
  height: 100%;
  text-align: left;
}
.old-brand-card:hover {
  cursor: pointer;
}
.old-brand-card-header {
  overflow: hidden;
  padding-top: 100%;
  width: 100%;
  background-color: #fff;
  border-radius: 4px 4px 0 0;
  box-sizing: border-box;
  position: relative;
}
.old-brand-card-header:before {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 4px 4px 0 0;
  box-sizing: border-box;
  box-shadow: inset 0 0 0 1px rgba(0,0,0,0.14);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
.old-brand-card-header .inner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  -webkit-transform: translate3d(-50%, -50%, 0);
  -moz-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
}
.old-brand-card-header img {
  width: 100%;
  height: auto;
  opacity: 0;
  will-change: opacity;
  -webkit-transition: opacity 400ms ease;
  -moz-transition: opacity 400ms ease;
  transition: opacity 400ms ease;
}
.old-brand-card-header img.loaded {
  opacity: 1;
}
.old-brand-card-body {
  flex: 1;
  padding: 20px;
  width: 100%;
  min-height: 120px;
  background-color: #fff;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  box-sizing: border-box;
}
.old-brand-card-body > span {
  color: #444;
}
.old-brand-card-body > div {
  padding-top: 10px;
  font-size: 14px;
  line-height: 21px;
}
.old-brand-card-body > div small {
  font-size: 12px;
  font-weight: 300;
  line-height: 18px;
}
.old-brand-card-body > div a {
  display: block;
  color: #444;
}
.old-brand-card-footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
  width: 100%;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 0 0 4px 4px;
  box-sizing: border-box;
}
.button-link {
  background-color: transparent;
  border: none;
  color: #c19141;
  text-decoration: none;
  cursor: pointer;
  font-family: "Lato", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 400;
}
.button {
  display: inline-block;
  padding: 12px 22px 12px 24px;
  background-color: #aaa;
  border: 2px solid transparent;
  border-radius: 4px;
  box-sizing: border-box;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  white-space: nowrap;
  cursor: pointer;
  will-change: background-color;
  position: relative;
  font-family: "Lato", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #fff;
  -webkit-transition: background-color 400ms ease;
  -moz-transition: background-color 400ms ease;
  transition: background-color 400ms ease;
  /*
    &:focus,
    &:hover,
    &:active {
      background-color: darken($grey_bb, 10); // to validate
    }
    */
}
.button-icon {
  padding: 10px;
}
.button-icon span {
  display: block;
}
.button-disabled {
  background-color: #ddd !important;
  pointer-events: none !important;
}
.button .loader {
  display: none;
  min-height: unset;
  position: absolute;
  top: 0;
  left: 0;
}
.button .loader span {
  background-color: #fff;
}
.button-loader {
  pointer-events: none;
}
.button-loader > span {
  opacity: 0;
}
.button-loader .loader {
  display: flex;
}
.button-outline {
  background-color: transparent;
  border-color: #ddd;
  color: #888;
}
.button-small {
  padding: 8px 10px 8px 12px;
  font-size: 12px;
  line-height: normal;
}
.button-large {
  padding: 0 30px;
  height: 64px;
  font-size: 16px;
  line-height: 64px;
}
.button-block {
  display: block;
  width: 100%;
  box-sizing: border-box;
}
.button.white {
  background-color: #fff;
  color: #888;
  /*
      &:focus,
      &:hover,
      &:active {
        background-color: darken($white, 10%);
      }
      */
}
.button-grey-lighter {
  background-color: #aaa;
}
.button-grey-lighter:focus,
.button-grey-lighter:hover,
.button-grey-lighter:active {
  background-color: #999;
}
.button.gold {
  background-color: #c19141;
}
.button.gold:focus,
.button.gold:hover,
.button.gold:active {
  background-color: #af8339;
}
.button.gold-dark {
  background-color: #af8339;
}
.button.gold-dark:focus,
.button.gold-dark:hover,
.button.gold-dark:active {
  background-color: #9e7633;
}
.button-primary {
  background-color: #c19141;
}
.button-primary:focus,
.button-primary:hover,
.button-primary:active {
  background-color: #af8339;
}
.button-primary.button-outline {
  background-color: transparent;
  border-color: #c19141;
  color: #c19141;
}
.button-primary.button-outline:focus,
.button-primary.button-outline:hover,
.button-primary.button-outline:active {
  background-color: transparent;
}
.button.info {
  background-color: #2980b9;
}
.button.info:focus,
.button.info:hover,
.button.info:active {
  background-color: #2573a7;
}
.button-success {
  background-color: #c19141;
}
.button-success:focus,
.button-success:hover,
.button-success:active {
  background-color: #af8339;
}
.button.danger,
.button-danger {
  background-color: #c0392b;
}
.button.danger:focus,
.button-danger:focus,
.button.danger:hover,
.button-danger:hover,
.button.danger:active,
.button-danger:active {
  background-color: #ad3327;
}
.card {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-align: left;
  /*
    &:hover {
      cursor: pointer;
    }
    */
}
.card > div:first-child {
  border-radius: 4px 4px 0 0;
}
.card > div:last-child {
  border-radius: 0 0 4px 4px;
}
.card > div:first-child:last-child {
  border-radius: 4px;
}
.card-header {
  overflow: hidden;
  padding-top: 75%;
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
  position: relative;
}
.card-header:before {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 4px 4px 0 0;
  box-sizing: border-box;
  box-shadow: inset 0 0 0 1px rgba(0,0,0,0.14);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
.card-header .inner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  -webkit-transform: translate3d(-50%, -50%, 0);
  -moz-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
}
.card-header img {
  width: 100%;
  height: auto;
  opacity: 0;
  will-change: opacity;
  -webkit-transition: opacity 400ms ease;
  -moz-transition: opacity 400ms ease;
  transition: opacity 400ms ease;
}
.card-header img.loaded {
  opacity: 1;
}
.card-header + .card-body {
  border-top: 0;
}
.card-body {
  flex: 1;
  padding: 20px;
  width: 100%;
  min-height: 120px;
  background-color: #fff;
  border: 1px solid #ddd;
  box-sizing: border-box;
}
.card-body > a {
  color: #444;
}
.card-body > div {
  padding-top: 10px;
  font-size: 14px;
  line-height: 21px;
}
.card-body > div small {
  font-size: 12px;
  font-weight: 300;
  line-height: 18px;
}
.card-body > div a {
  display: block;
  color: #444;
}
.card-footer {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 10px;
  width: 100%;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-top: 0;
  box-sizing: border-box;
}
.icon.icon-edit {
  background-image: url("assets/images/sprite.svg");
  background-repeat: no-repeat;
  background-size: 400px 200px;
  background-position: -256px 0;
  width: 24px;
  height: 24px;
}
.icon.icon-delete {
  background-image: url("assets/images/sprite.svg");
  background-repeat: no-repeat;
  background-size: 400px 200px;
  background-position: -280px 0;
  width: 24px;
  height: 24px;
}
.icon.icon-more-v {
  background-image: url("assets/images/sprite.svg");
  background-repeat: no-repeat;
  background-size: 400px 200px;
  background-position: -188px 0;
  width: 24px;
  height: 24px;
}
.icon.icon-more-h {
  opacity: 0.5;
  background-image: url("assets/images/sprite.svg");
  background-repeat: no-repeat;
  background-size: 400px 200px;
  background-position: -160px 0;
  width: 24px;
  height: 24px;
}
.card-grid {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
}
.card-grid > li {
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
}
.card-grid.col-2 > li {
  width: 50%;
}
.card-grid.col-3 > li {
  width: 33.33%;
}
.card-grid.col-4 > li {
  width: 25%;
}
.card-load-more {
  padding: 20px 40px;
  text-align: center;
}
.card2 {
  display: flex;
  width: 100%;
  text-align: left;
  /*
    &:hover {
      cursor: pointer;
    }
    */
}
.card2 > div:first-child {
  border-radius: 4px 0 0 4px;
}
.card2 > div:last-child {
  border-radius: 0 4px 4px 0;
}
.card2 > div:first-child:last-child {
  border-radius: 4px;
}
.card2-header {
  overflow: hidden;
  width: 100%;
  max-width: 64px;
  height: 64px;
  background-color: #fff;
  box-sizing: border-box;
  position: relative;
}
.card2-header:before {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 4px 4px 0 0;
  box-sizing: border-box;
  box-shadow: inset 0 0 0 1px rgba(0,0,0,0.14);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
.card2-header .inner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  -webkit-transform: translate3d(-50%, -50%, 0);
  -moz-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
}
.card2-header img {
  width: 100%;
  height: auto;
  opacity: 0;
  will-change: opacity;
  -webkit-transition: opacity 400ms ease;
  -moz-transition: opacity 400ms ease;
  transition: opacity 400ms ease;
}
.card2-header img.loaded {
  opacity: 1;
}
.card2-header + .card2-body {
  border-left: 0;
}
.card2-body {
  flex: 1;
  padding: 20px;
  width: 100%;
  hight: 100%;
  background-color: #fff;
  border: 1px solid #ddd;
  border-right: 0;
  box-sizing: border-box;
}
.card2-body > a {
  color: #444;
}
.card2-body > div {
  padding-top: 10px;
  font-size: 14px;
  line-height: 21px;
}
.card2-body > div small {
  font-size: 12px;
  font-weight: 300;
  line-height: 18px;
}
.card2-body > div a {
  display: block;
  color: #444;
}
.card2-footer {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 10px;
  border: 1px solid #ddd;
  border-left: 0;
  box-sizing: border-box;
}
.card2-footer a {
  padding: 4px;
  background-color: transparent;
  border-radius: 4px;
  will-change: background-color;
  -webkit-transition: background-color 400ms ease;
  -moz-transition: background-color 400ms ease;
  transition: background-color 400ms ease;
}
.card2-footer a:hover {
  background-color: #e5e5e5;
}
.card2-footer a span {
  display: block;
}
.icon.icon-edit {
  background-image: url("assets/images/sprite.svg");
  background-repeat: no-repeat;
  background-size: 400px 200px;
  background-position: -256px 0;
  width: 24px;
  height: 24px;
}
.icon.icon-delete {
  background-image: url("assets/images/sprite.svg");
  background-repeat: no-repeat;
  background-size: 400px 200px;
  background-position: -280px 0;
  width: 24px;
  height: 24px;
}
.icon.icon-more-v {
  background-image: url("assets/images/sprite.svg");
  background-repeat: no-repeat;
  background-size: 400px 200px;
  background-position: -188px 0;
  width: 24px;
  height: 24px;
}
.icon.icon-more-h {
  opacity: 0.5;
  background-image: url("assets/images/sprite.svg");
  background-repeat: no-repeat;
  background-size: 400px 200px;
  background-position: -160px 0;
  width: 24px;
  height: 24px;
}
.card2-grid {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
}
.card2-grid > li {
  padding: 0 20px;
  width: 100%;
  box-sizing: border-box;
}
.card2-grid.col-2 > li {
  width: 50%;
}
.card2-grid.col-3 > li {
  width: 33.33%;
}
.card2-grid.col-4 > li {
  width: 25%;
}
.card2-load-more {
  padding: 20px 40px;
  text-align: center;
}
.field label {
  display: block;
  padding: 0 0 10px 0;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: "Lato", "Arial", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  color: #666;
}
.field label span {
  color: #aaa;
}
.field-info,
.field-error {
  display: block;
  padding: 10px 0 0 0;
  font-family: "Lato", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  color: #888;
}
.field-error {
  color: #c0392b;
}
.icon-intlpn-flag {
  width: 48px;
}
.icon-intlpn-flag:before {
  content: '';
  width: 32px;
  height: 32px;
  background-repeat: no-repeat;
  background-color: transparent;
  display: inline-block;
  vertical-align: middle;
}
.icon-intlpn-flag.np:before {
  background-color: transparent;
}
.icon-intlpn-flag:before,
.icon-intlpn-flag.ad:before,
.icon-intlpn-flag.ae:before,
.icon-intlpn-flag.af:before,
.icon-intlpn-flag.ag:before,
.icon-intlpn-flag.ai:before,
.icon-intlpn-flag.al:before,
.icon-intlpn-flag.am:before,
.icon-intlpn-flag.an:before,
.icon-intlpn-flag.ao:before,
.icon-intlpn-flag.aq:before,
.icon-intlpn-flag.ar:before,
.icon-intlpn-flag.as:before,
.icon-intlpn-flag.at:before,
.icon-intlpn-flag.au:before,
.icon-intlpn-flag.aw:before,
.icon-intlpn-flag.ax:before,
.icon-intlpn-flag.az:before,
.icon-intlpn-flag.ba:before,
.icon-intlpn-flag.bb:before,
.icon-intlpn-flag.bd:before,
.icon-intlpn-flag.be:before,
.icon-intlpn-flag.bf:before,
.icon-intlpn-flag.bg:before,
.icon-intlpn-flag.bh:before,
.icon-intlpn-flag.bi:before,
.icon-intlpn-flag.bj:before,
.icon-intlpn-flag.bl:before,
.icon-intlpn-flag.bm:before,
.icon-intlpn-flag.bn:before,
.icon-intlpn-flag.bo:before,
.icon-intlpn-flag.br:before,
.icon-intlpn-flag.bs:before,
.icon-intlpn-flag.bt:before,
.icon-intlpn-flag.bw:before,
.icon-intlpn-flag.by:before,
.icon-intlpn-flag.bz:before,
.icon-intlpn-flag.ca:before,
.icon-intlpn-flag.cc:before,
.icon-intlpn-flag.cd:before,
.icon-intlpn-flag.cf:before,
.icon-intlpn-flag.cg:before,
.icon-intlpn-flag.ch:before,
.icon-intlpn-flag.ci:before,
.icon-intlpn-flag.ck:before,
.icon-intlpn-flag.cl:before,
.icon-intlpn-flag.cm:before,
.icon-intlpn-flag.cn:before,
.icon-intlpn-flag.co:before,
.icon-intlpn-flag.cr:before,
.icon-intlpn-flag.cu:before,
.icon-intlpn-flag.cv:before,
.icon-intlpn-flag.cw:before,
.icon-intlpn-flag.cx:before,
.icon-intlpn-flag.cy:before,
.icon-intlpn-flag.cz:before,
.icon-intlpn-flag.de:before,
.icon-intlpn-flag.dj:before,
.icon-intlpn-flag.dk:before,
.icon-intlpn-flag.dm:before,
.icon-intlpn-flag.do:before,
.icon-intlpn-flag.dz:before,
.icon-intlpn-flag.ec:before,
.icon-intlpn-flag.ee:before,
.icon-intlpn-flag.eg:before,
.icon-intlpn-flag.eh:before,
.icon-intlpn-flag.er:before,
.icon-intlpn-flag.es:before,
.icon-intlpn-flag.et:before,
.icon-intlpn-flag.eu:before,
.icon-intlpn-flag.fi:before,
.icon-intlpn-flag.fj:before,
.icon-intlpn-flag.fk:before,
.icon-intlpn-flag.fm:before,
.icon-intlpn-flag.fo:before,
.icon-intlpn-flag.fr:before,
.icon-intlpn-flag.ga:before,
.icon-intlpn-flag.gb:before,
.icon-intlpn-flag.gd:before,
.icon-intlpn-flag.ge:before,
.icon-intlpn-flag.gg:before,
.icon-intlpn-flag.gh:before,
.icon-intlpn-flag.gi:before,
.icon-intlpn-flag.gl:before,
.icon-intlpn-flag.gm:before,
.icon-intlpn-flag.gn:before,
.icon-intlpn-flag.gq:before,
.icon-intlpn-flag.gr:before,
.icon-intlpn-flag.gs:before,
.icon-intlpn-flag.gt:before,
.icon-intlpn-flag.gu:before,
.icon-intlpn-flag.gw:before,
.icon-intlpn-flag.gy:before,
.icon-intlpn-flag.hk:before,
.icon-intlpn-flag.hn:before,
.icon-intlpn-flag.hr:before,
.icon-intlpn-flag.ht:before,
.icon-intlpn-flag.hu:before,
.icon-intlpn-flag.ic:before,
.icon-intlpn-flag.id:before,
.icon-intlpn-flag.ie:before,
.icon-intlpn-flag.il:before,
.icon-intlpn-flag.im:before,
.icon-intlpn-flag.in:before,
.icon-intlpn-flag.iq:before,
.icon-intlpn-flag.ir:before,
.icon-intlpn-flag.is:before,
.icon-intlpn-flag.it:before,
.icon-intlpn-flag.je:before,
.icon-intlpn-flag.jm:before,
.icon-intlpn-flag.jo:before,
.icon-intlpn-flag.jp:before,
.icon-intlpn-flag.ke:before,
.icon-intlpn-flag.kg:before,
.icon-intlpn-flag.kh:before,
.icon-intlpn-flag.ki:before,
.icon-intlpn-flag.km:before,
.icon-intlpn-flag.kn:before,
.icon-intlpn-flag.kp:before,
.icon-intlpn-flag.kr:before,
.icon-intlpn-flag.kw:before,
.icon-intlpn-flag.ky:before,
.icon-intlpn-flag.kz:before,
.icon-intlpn-flag.la:before,
.icon-intlpn-flag.lb:before,
.icon-intlpn-flag.lc:before,
.icon-intlpn-flag.li:before,
.icon-intlpn-flag.lk:before,
.icon-intlpn-flag.lr:before,
.icon-intlpn-flag.ls:before,
.icon-intlpn-flag.lt:before,
.icon-intlpn-flag.lu:before,
.icon-intlpn-flag.lv:before,
.icon-intlpn-flag.ly:before,
.icon-intlpn-flag.ma:before,
.icon-intlpn-flag.mc:before,
.icon-intlpn-flag.md:before,
.icon-intlpn-flag.me:before,
.icon-intlpn-flag.mf:before,
.icon-intlpn-flag.mg:before,
.icon-intlpn-flag.mh:before,
.icon-intlpn-flag.mk:before,
.icon-intlpn-flag.ml:before,
.icon-intlpn-flag.mm:before,
.icon-intlpn-flag.mn:before,
.icon-intlpn-flag.mo:before,
.icon-intlpn-flag.mp:before,
.icon-intlpn-flag.mq:before,
.icon-intlpn-flag.mr:before,
.icon-intlpn-flag.ms:before,
.icon-intlpn-flag.mt:before,
.icon-intlpn-flag.mu:before,
.icon-intlpn-flag.mv:before,
.icon-intlpn-flag.mw:before,
.icon-intlpn-flag.mx:before,
.icon-intlpn-flag.my:before,
.icon-intlpn-flag.mz:before,
.icon-intlpn-flag.na:before,
.icon-intlpn-flag.nc:before,
.icon-intlpn-flag.ne:before,
.icon-intlpn-flag.nf:before,
.icon-intlpn-flag.ng:before,
.icon-intlpn-flag.ni:before,
.icon-intlpn-flag.nl:before,
.icon-intlpn-flag.no:before,
.icon-intlpn-flag.np:before,
.icon-intlpn-flag.nr:before,
.icon-intlpn-flag.nu:before,
.icon-intlpn-flag.nz:before,
.icon-intlpn-flag.om:before,
.icon-intlpn-flag.pa:before,
.icon-intlpn-flag.pe:before,
.icon-intlpn-flag.pf:before,
.icon-intlpn-flag.pg:before,
.icon-intlpn-flag.ph:before,
.icon-intlpn-flag.pk:before,
.icon-intlpn-flag.pl:before,
.icon-intlpn-flag.pn:before,
.icon-intlpn-flag.pr:before,
.icon-intlpn-flag.ps:before,
.icon-intlpn-flag.pt:before,
.icon-intlpn-flag.pw:before,
.icon-intlpn-flag.py:before,
.icon-intlpn-flag.qa:before,
.icon-intlpn-flag.ro:before,
.icon-intlpn-flag.rs:before,
.icon-intlpn-flag.ru:before,
.icon-intlpn-flag.rw:before,
.icon-intlpn-flag.sa:before,
.icon-intlpn-flag.sb:before,
.icon-intlpn-flag.sc:before,
.icon-intlpn-flag.sd:before,
.icon-intlpn-flag.se:before,
.icon-intlpn-flag.sg:before,
.icon-intlpn-flag.sh:before,
.icon-intlpn-flag.si:before,
.icon-intlpn-flag.sk:before,
.icon-intlpn-flag.sl:before,
.icon-intlpn-flag.sm:before,
.icon-intlpn-flag.sn:before,
.icon-intlpn-flag.so:before,
.icon-intlpn-flag.sr:before,
.icon-intlpn-flag.ss:before,
.icon-intlpn-flag.st:before,
.icon-intlpn-flag.sv:before,
.icon-intlpn-flag.sy:before,
.icon-intlpn-flag.sz:before,
.icon-intlpn-flag.tc:before,
.icon-intlpn-flag.td:before,
.icon-intlpn-flag.tf:before,
.icon-intlpn-flag.tg:before,
.icon-intlpn-flag.th:before,
.icon-intlpn-flag.tj:before,
.icon-intlpn-flag.tk:before,
.icon-intlpn-flag.tl:before,
.icon-intlpn-flag.tm:before,
.icon-intlpn-flag.tn:before,
.icon-intlpn-flag.to:before,
.icon-intlpn-flag.tr:before,
.icon-intlpn-flag.tt:before,
.icon-intlpn-flag.tv:before,
.icon-intlpn-flag.tw:before,
.icon-intlpn-flag.tz:before,
.icon-intlpn-flag.ua:before,
.icon-intlpn-flag.ug:before,
.icon-intlpn-flag.us:before,
.icon-intlpn-flag.uy:before,
.icon-intlpn-flag.uz:before,
.icon-intlpn-flag.va:before,
.icon-intlpn-flag.vc:before,
.icon-intlpn-flag.ve:before,
.icon-intlpn-flag.vg:before,
.icon-intlpn-flag.vi:before,
.icon-intlpn-flag.vn:before,
.icon-intlpn-flag.vu:before,
.icon-intlpn-flag.wf:before,
.icon-intlpn-flag.ws:before,
.icon-intlpn-flag.ye:before,
.icon-intlpn-flag.yt:before,
.icon-intlpn-flag.za:before,
.icon-intlpn-flag.zm:before,
.icon-intlpn-flag.zw:before,
.icon-intlpn-flag._abkhazia:before,
.icon-intlpn-flag._basque-country:before,
.icon-intlpn-flag._british-antarctic-territory:before,
.icon-intlpn-flag._commonwealth:before,
.icon-intlpn-flag._england:before,
.icon-intlpn-flag._gosquared:before,
.icon-intlpn-flag._kosovo:before,
.icon-intlpn-flag._mars:before,
.icon-intlpn-flag._nagorno-karabakh:before,
.icon-intlpn-flag._nato:before,
.icon-intlpn-flag._northern-cyprus:before,
.icon-intlpn-flag._olympics:before,
.icon-intlpn-flag._red-cross:before,
.icon-intlpn-flag._scotland:before,
.icon-intlpn-flag._somaliland:before,
.icon-intlpn-flag._south-ossetia:before,
.icon-intlpn-flag._united-nations:before,
.icon-intlpn-flag._unknown:before,
.icon-intlpn-flag._wales:before,
.icon-intlpn-flag.io:before,
.icon-intlpn-flag.bq:before,
.icon-intlpn-flag.gf:before,
.icon-intlpn-flag.gp:before,
.icon-intlpn-flag.re:before,
.icon-intlpn-flag.pm:before,
.icon-intlpn-flag.sx:before {
  background-image: url("assets/images/flags.png");
  background-repeat: no-repeat;
  background-size: 32px 8544px;
  height: 32px;
  width: 32px;
  background-position: 0 -8480px;
}
.icon-intlpn-flag.none:before {
  background-image: none;
  background-color: #f8f8f8;
  content: '?';
  text-align: center;
  vertical-align: middle;
  line-height: 32px;
  color: #999;
  font-weight: bold;
}
.icon-intlpn-flag.ad:before {
  background-position: 0 0;
}
.icon-intlpn-flag.ae:before {
  background-position: 0 -32px;
}
.icon-intlpn-flag.af:before {
  background-position: 0 -64px;
}
.icon-intlpn-flag.ag:before {
  background-position: 0 -96px;
}
.icon-intlpn-flag.ai:before {
  background-position: 0 -128px;
}
.icon-intlpn-flag.al:before {
  background-position: 0 -160px;
}
.icon-intlpn-flag.am:before {
  background-position: 0 -192px;
}
.icon-intlpn-flag.an:before {
  background-position: 0 -224px;
}
.icon-intlpn-flag.ao:before {
  background-position: 0 -256px;
}
.icon-intlpn-flag.aq:before {
  background-position: 0 -288px;
}
.icon-intlpn-flag.ar:before {
  background-position: 0 -320px;
}
.icon-intlpn-flag.as:before {
  background-position: 0 -352px;
}
.icon-intlpn-flag.at:before {
  background-position: 0 -384px;
}
.icon-intlpn-flag.au:before {
  background-position: 0 -416px;
}
.icon-intlpn-flag.aw:before {
  background-position: 0 -448px;
}
.icon-intlpn-flag.ax:before {
  background-position: 0 -480px;
}
.icon-intlpn-flag.az:before {
  background-position: 0 -512px;
}
.icon-intlpn-flag.ba:before {
  background-position: 0 -544px;
}
.icon-intlpn-flag.bb:before {
  background-position: 0 -576px;
}
.icon-intlpn-flag.bd:before {
  background-position: 0 -608px;
}
.icon-intlpn-flag.be:before {
  background-position: 0 -640px;
}
.icon-intlpn-flag.bf:before {
  background-position: 0 -672px;
}
.icon-intlpn-flag.bg:before {
  background-position: 0 -704px;
}
.icon-intlpn-flag.bh:before {
  background-position: 0 -736px;
}
.icon-intlpn-flag.bi:before {
  background-position: 0 -768px;
}
.icon-intlpn-flag.bj:before {
  background-position: 0 -800px;
}
.icon-intlpn-flag.bl:before {
  background-position: 0 -832px;
}
.icon-intlpn-flag.bm:before {
  background-position: 0 -864px;
}
.icon-intlpn-flag.bn:before {
  background-position: 0 -896px;
}
.icon-intlpn-flag.bo:before {
  background-position: 0 -928px;
}
.icon-intlpn-flag.bq:before {
  background-position: 0 -960px;
}
.icon-intlpn-flag.br:before {
  background-position: 0 -992px;
}
.icon-intlpn-flag.bs:before {
  background-position: 0 -1024px;
}
.icon-intlpn-flag.bt:before {
  background-position: 0 -1056px;
}
.icon-intlpn-flag.bw:before {
  background-position: 0 -1088px;
}
.icon-intlpn-flag.by:before {
  background-position: 0 -1120px;
}
.icon-intlpn-flag.bz:before {
  background-position: 0 -1152px;
}
.icon-intlpn-flag.ca:before {
  background-position: 0 -1184px;
}
.icon-intlpn-flag.cc:before {
  background-position: 0 -1216px;
}
.icon-intlpn-flag.cd:before {
  background-position: 0 -1248px;
}
.icon-intlpn-flag.cf:before {
  background-position: 0 -1280px;
}
.icon-intlpn-flag.cg:before {
  background-position: 0 -1312px;
}
.icon-intlpn-flag.ch:before {
  background-position: 0 -1344px;
}
.icon-intlpn-flag.ci:before {
  background-position: 0 -1376px;
}
.icon-intlpn-flag.ck:before {
  background-position: 0 -1408px;
}
.icon-intlpn-flag.cl:before {
  background-position: 0 -1440px;
}
.icon-intlpn-flag.cm:before {
  background-position: 0 -1472px;
}
.icon-intlpn-flag.cn:before {
  background-position: 0 -1504px;
}
.icon-intlpn-flag.co:before {
  background-position: 0 -1536px;
}
.icon-intlpn-flag.cr:before {
  background-position: 0 -1568px;
}
.icon-intlpn-flag.cu:before {
  background-position: 0 -1600px;
}
.icon-intlpn-flag.cv:before {
  background-position: 0 -1632px;
}
.icon-intlpn-flag.cw:before {
  background-position: 0 -1664px;
}
.icon-intlpn-flag.cx:before {
  background-position: 0 -1696px;
}
.icon-intlpn-flag.cy:before {
  background-position: 0 -1728px;
}
.icon-intlpn-flag.cz:before {
  background-position: 0 -1760px;
}
.icon-intlpn-flag.de:before {
  background-position: 0 -1792px;
}
.icon-intlpn-flag.dj:before {
  background-position: 0 -1824px;
}
.icon-intlpn-flag.dk:before {
  background-position: 0 -1856px;
}
.icon-intlpn-flag.dm:before {
  background-position: 0 -1888px;
}
.icon-intlpn-flag.do:before {
  background-position: 0 -1920px;
}
.icon-intlpn-flag.dz:before {
  background-position: 0 -1952px;
}
.icon-intlpn-flag.ec:before {
  background-position: 0 -1984px;
}
.icon-intlpn-flag.ee:before {
  background-position: 0 -2016px;
}
.icon-intlpn-flag.eg:before {
  background-position: 0 -2048px;
}
.icon-intlpn-flag.eh:before {
  background-position: 0 -2080px;
}
.icon-intlpn-flag.er:before {
  background-position: 0 -2112px;
}
.icon-intlpn-flag.es:before {
  background-position: 0 -2144px;
}
.icon-intlpn-flag.et:before {
  background-position: 0 -2176px;
}
.icon-intlpn-flag.eu:before {
  background-position: 0 -2208px;
}
.icon-intlpn-flag.fi:before {
  background-position: 0 -2240px;
}
.icon-intlpn-flag.fj:before {
  background-position: 0 -2272px;
}
.icon-intlpn-flag.fk:before {
  background-position: 0 -2304px;
}
.icon-intlpn-flag.fm:before {
  background-position: 0 -2336px;
}
.icon-intlpn-flag.fo:before {
  background-position: 0 -2368px;
}
.icon-intlpn-flag.fr:before {
  background-position: 0 -2400px;
}
.icon-intlpn-flag.ga:before {
  background-position: 0 -2432px;
}
.icon-intlpn-flag.gb:before {
  background-position: 0 -2464px;
}
.icon-intlpn-flag.gd:before {
  background-position: 0 -2496px;
}
.icon-intlpn-flag.ge:before {
  background-position: 0 -2528px;
}
.icon-intlpn-flag.gf:before {
  background-position: 0 -2560px;
}
.icon-intlpn-flag.gg:before {
  background-position: 0 -2592px;
}
.icon-intlpn-flag.gh:before {
  background-position: 0 -2624px;
}
.icon-intlpn-flag.gi:before {
  background-position: 0 -2656px;
}
.icon-intlpn-flag.gl:before {
  background-position: 0 -2688px;
}
.icon-intlpn-flag.gm:before {
  background-position: 0 -2720px;
}
.icon-intlpn-flag.gn:before {
  background-position: 0 -2752px;
}
.icon-intlpn-flag.gp:before {
  background-position: 0 -2784px;
}
.icon-intlpn-flag.gq:before {
  background-position: 0 -2816px;
}
.icon-intlpn-flag.gr:before {
  background-position: 0 -2848px;
}
.icon-intlpn-flag.gs:before {
  background-position: 0 -2880px;
}
.icon-intlpn-flag.gt:before {
  background-position: 0 -2912px;
}
.icon-intlpn-flag.gu:before {
  background-position: 0 -2944px;
}
.icon-intlpn-flag.gw:before {
  background-position: 0 -2976px;
}
.icon-intlpn-flag.gy:before {
  background-position: 0 -3008px;
}
.icon-intlpn-flag.hk:before {
  background-position: 0 -3040px;
}
.icon-intlpn-flag.hn:before {
  background-position: 0 -3072px;
}
.icon-intlpn-flag.hr:before {
  background-position: 0 -3104px;
}
.icon-intlpn-flag.ht:before {
  background-position: 0 -3136px;
}
.icon-intlpn-flag.hu:before {
  background-position: 0 -3168px;
}
.icon-intlpn-flag.ic:before {
  background-position: 0 -3200px;
}
.icon-intlpn-flag.id:before {
  background-position: 0 -3232px;
}
.icon-intlpn-flag.ie:before {
  background-position: 0 -3264px;
}
.icon-intlpn-flag.il:before {
  background-position: 0 -3296px;
}
.icon-intlpn-flag.im:before {
  background-position: 0 -3328px;
}
.icon-intlpn-flag.in:before {
  background-position: 0 -3360px;
}
.icon-intlpn-flag.io:before {
  background-position: 0 -3392px;
}
.icon-intlpn-flag.iq:before {
  background-position: 0 -3424px;
}
.icon-intlpn-flag.ir:before {
  background-position: 0 -3456px;
}
.icon-intlpn-flag.is:before {
  background-position: 0 -3488px;
}
.icon-intlpn-flag.it:before {
  background-position: 0 -3520px;
}
.icon-intlpn-flag.je:before {
  background-position: 0 -3552px;
}
.icon-intlpn-flag.jm:before {
  background-position: 0 -3584px;
}
.icon-intlpn-flag.jo:before {
  background-position: 0 -3616px;
}
.icon-intlpn-flag.jp:before {
  background-position: 0 -3648px;
}
.icon-intlpn-flag.ke:before {
  background-position: 0 -3680px;
}
.icon-intlpn-flag.kg:before {
  background-position: 0 -3712px;
}
.icon-intlpn-flag.kh:before {
  background-position: 0 -3744px;
}
.icon-intlpn-flag.ki:before {
  background-position: 0 -3776px;
}
.icon-intlpn-flag.km:before {
  background-position: 0 -3808px;
}
.icon-intlpn-flag.kn:before {
  background-position: 0 -3840px;
}
.icon-intlpn-flag.kp:before {
  background-position: 0 -3872px;
}
.icon-intlpn-flag.kr:before {
  background-position: 0 -3904px;
}
.icon-intlpn-flag.kw:before {
  background-position: 0 -3936px;
}
.icon-intlpn-flag.ky:before {
  background-position: 0 -3968px;
}
.icon-intlpn-flag.kz:before {
  background-position: 0 -4000px;
}
.icon-intlpn-flag.la:before {
  background-position: 0 -4032px;
}
.icon-intlpn-flag.lb:before {
  background-position: 0 -4064px;
}
.icon-intlpn-flag.lc:before {
  background-position: 0 -4096px;
}
.icon-intlpn-flag.li:before {
  background-position: 0 -4128px;
}
.icon-intlpn-flag.lk:before {
  background-position: 0 -4160px;
}
.icon-intlpn-flag.lr:before {
  background-position: 0 -4192px;
}
.icon-intlpn-flag.ls:before {
  background-position: 0 -4224px;
}
.icon-intlpn-flag.lt:before {
  background-position: 0 -4256px;
}
.icon-intlpn-flag.lu:before {
  background-position: 0 -4288px;
}
.icon-intlpn-flag.lv:before {
  background-position: 0 -4320px;
}
.icon-intlpn-flag.ly:before {
  background-position: 0 -4352px;
}
.icon-intlpn-flag.ma:before {
  background-position: 0 -4384px;
}
.icon-intlpn-flag.mc:before {
  background-position: 0 -4416px;
}
.icon-intlpn-flag.md:before {
  background-position: 0 -4448px;
}
.icon-intlpn-flag.me:before {
  background-position: 0 -4480px;
}
.icon-intlpn-flag.mf:before {
  background-position: 0 -4512px;
}
.icon-intlpn-flag.mg:before {
  background-position: 0 -4544px;
}
.icon-intlpn-flag.mh:before {
  background-position: 0 -4576px;
}
.icon-intlpn-flag.mk:before {
  background-position: 0 -4608px;
}
.icon-intlpn-flag.ml:before {
  background-position: 0 -4640px;
}
.icon-intlpn-flag.mm:before {
  background-position: 0 -4672px;
}
.icon-intlpn-flag.mn:before {
  background-position: 0 -4704px;
}
.icon-intlpn-flag.mo:before {
  background-position: 0 -4736px;
}
.icon-intlpn-flag.mp:before {
  background-position: 0 -4768px;
}
.icon-intlpn-flag.mq:before {
  background-position: 0 -4800px;
}
.icon-intlpn-flag.mr:before {
  background-position: 0 -4832px;
}
.icon-intlpn-flag.ms:before {
  background-position: 0 -4864px;
}
.icon-intlpn-flag.mt:before {
  background-position: 0 -4896px;
}
.icon-intlpn-flag.mu:before {
  background-position: 0 -4928px;
}
.icon-intlpn-flag.mv:before {
  background-position: 0 -4960px;
}
.icon-intlpn-flag.mw:before {
  background-position: 0 -4992px;
}
.icon-intlpn-flag.mx:before {
  background-position: 0 -5024px;
}
.icon-intlpn-flag.my:before {
  background-position: 0 -5056px;
}
.icon-intlpn-flag.mz:before {
  background-position: 0 -5088px;
}
.icon-intlpn-flag.na:before {
  background-position: 0 -5120px;
}
.icon-intlpn-flag.nc:before {
  background-position: 0 -5152px;
}
.icon-intlpn-flag.ne:before {
  background-position: 0 -5184px;
}
.icon-intlpn-flag.nf:before {
  background-position: 0 -5216px;
}
.icon-intlpn-flag.ng:before {
  background-position: 0 -5248px;
}
.icon-intlpn-flag.ni:before {
  background-position: 0 -5280px;
}
.icon-intlpn-flag.nl:before {
  background-position: 0 -5312px;
}
.icon-intlpn-flag.no:before {
  background-position: 0 -5344px;
}
.icon-intlpn-flag.np:before {
  background-position: 0 -5376px;
}
.icon-intlpn-flag.nr:before {
  background-position: 0 -5408px;
}
.icon-intlpn-flag.nu:before {
  background-position: 0 -5440px;
}
.icon-intlpn-flag.nz:before {
  background-position: 0 -5472px;
}
.icon-intlpn-flag.om:before {
  background-position: 0 -5504px;
}
.icon-intlpn-flag.pa:before {
  background-position: 0 -5536px;
}
.icon-intlpn-flag.pe:before {
  background-position: 0 -5568px;
}
.icon-intlpn-flag.pf:before {
  background-position: 0 -5600px;
}
.icon-intlpn-flag.pg:before {
  background-position: 0 -5632px;
}
.icon-intlpn-flag.ph:before {
  background-position: 0 -5664px;
}
.icon-intlpn-flag.pk:before {
  background-position: 0 -5696px;
}
.icon-intlpn-flag.pl:before {
  background-position: 0 -5728px;
}
.icon-intlpn-flag.pm:before {
  background-position: 0 -5760px;
}
.icon-intlpn-flag.pn:before {
  background-position: 0 -5792px;
}
.icon-intlpn-flag.pr:before {
  background-position: 0 -5824px;
}
.icon-intlpn-flag.ps:before {
  background-position: 0 -5856px;
}
.icon-intlpn-flag.pt:before {
  background-position: 0 -5888px;
}
.icon-intlpn-flag.pw:before {
  background-position: 0 -5920px;
}
.icon-intlpn-flag.py:before {
  background-position: 0 -5952px;
}
.icon-intlpn-flag.qa:before {
  background-position: 0 -5984px;
}
.icon-intlpn-flag.re:before {
  background-position: 0 -6016px;
}
.icon-intlpn-flag.ro:before {
  background-position: 0 -6048px;
}
.icon-intlpn-flag.rs:before {
  background-position: 0 -6080px;
}
.icon-intlpn-flag.ru:before {
  background-position: 0 -6112px;
}
.icon-intlpn-flag.rw:before {
  background-position: 0 -6144px;
}
.icon-intlpn-flag.sa:before {
  background-position: 0 -6176px;
}
.icon-intlpn-flag.sb:before {
  background-position: 0 -6208px;
}
.icon-intlpn-flag.sc:before {
  background-position: 0 -6240px;
}
.icon-intlpn-flag.sd:before {
  background-position: 0 -6272px;
}
.icon-intlpn-flag.se:before {
  background-position: 0 -6304px;
}
.icon-intlpn-flag.sg:before {
  background-position: 0 -6336px;
}
.icon-intlpn-flag.sh:before {
  background-position: 0 -6368px;
}
.icon-intlpn-flag.si:before {
  background-position: 0 -6400px;
}
.icon-intlpn-flag.sk:before {
  background-position: 0 -6432px;
}
.icon-intlpn-flag.sl:before {
  background-position: 0 -6464px;
}
.icon-intlpn-flag.sm:before {
  background-position: 0 -6496px;
}
.icon-intlpn-flag.sn:before {
  background-position: 0 -6528px;
}
.icon-intlpn-flag.so:before {
  background-position: 0 -6560px;
}
.icon-intlpn-flag.sr:before {
  background-position: 0 -6592px;
}
.icon-intlpn-flag.ss:before {
  background-position: 0 -6624px;
}
.icon-intlpn-flag.st:before {
  background-position: 0 -6656px;
}
.icon-intlpn-flag.sv:before {
  background-position: 0 -6688px;
}
.icon-intlpn-flag.sx:before {
  background-position: 0 -6720px;
}
.icon-intlpn-flag.sy:before {
  background-position: 0 -6752px;
}
.icon-intlpn-flag.sz:before {
  background-position: 0 -6784px;
}
.icon-intlpn-flag.tc:before {
  background-position: 0 -6816px;
}
.icon-intlpn-flag.td:before {
  background-position: 0 -6848px;
}
.icon-intlpn-flag.tf:before {
  background-position: 0 -6880px;
}
.icon-intlpn-flag.tg:before {
  background-position: 0 -6912px;
}
.icon-intlpn-flag.th:before {
  background-position: 0 -6944px;
}
.icon-intlpn-flag.tj:before {
  background-position: 0 -6976px;
}
.icon-intlpn-flag.tk:before {
  background-position: 0 -7008px;
}
.icon-intlpn-flag.tl:before {
  background-position: 0 -7040px;
}
.icon-intlpn-flag.tm:before {
  background-position: 0 -7072px;
}
.icon-intlpn-flag.tn:before {
  background-position: 0 -7104px;
}
.icon-intlpn-flag.to:before {
  background-position: 0 -7136px;
}
.icon-intlpn-flag.tr:before {
  background-position: 0 -7168px;
}
.icon-intlpn-flag.tt:before {
  background-position: 0 -7200px;
}
.icon-intlpn-flag.tv:before {
  background-position: 0 -7232px;
}
.icon-intlpn-flag.tw:before {
  background-position: 0 -7264px;
}
.icon-intlpn-flag.tz:before {
  background-position: 0 -7296px;
}
.icon-intlpn-flag.ua:before {
  background-position: 0 -7328px;
}
.icon-intlpn-flag.ug:before {
  background-position: 0 -7360px;
}
.icon-intlpn-flag.us:before {
  background-position: 0 -7392px;
}
.icon-intlpn-flag.uy:before {
  background-position: 0 -7424px;
}
.icon-intlpn-flag.uz:before {
  background-position: 0 -7456px;
}
.icon-intlpn-flag.va:before {
  background-position: 0 -7488px;
}
.icon-intlpn-flag.vc:before {
  background-position: 0 -7520px;
}
.icon-intlpn-flag.ve:before {
  background-position: 0 -7552px;
}
.icon-intlpn-flag.vg:before {
  background-position: 0 -7584px;
}
.icon-intlpn-flag.vi:before {
  background-position: 0 -7616px;
}
.icon-intlpn-flag.vn:before {
  background-position: 0 -7648px;
}
.icon-intlpn-flag.vu:before {
  background-position: 0 -7680px;
}
.icon-intlpn-flag.wf:before {
  background-position: 0 -7712px;
}
.icon-intlpn-flag.ws:before {
  background-position: 0 -7744px;
}
.icon-intlpn-flag.ye:before {
  background-position: 0 -7776px;
}
.icon-intlpn-flag.yt:before {
  background-position: 0 -7808px;
}
.icon-intlpn-flag.za:before {
  background-position: 0 -7840px;
}
.icon-intlpn-flag.zm:before {
  background-position: 0 -7872px;
}
.icon-intlpn-flag.zw:before {
  background-position: 0 -7904px;
}
.icon-intlpn-flag._abkhazia:before {
  background-position: 0 -7936px;
}
.icon-intlpn-flag._basque-country:before {
  background-position: 0 -7968px;
}
.icon-intlpn-flag._british-antarctic-territory:before {
  background-position: 0 -8000px;
}
.icon-intlpn-flag._commonwealth:before {
  background-position: 0 -8032px;
}
.icon-intlpn-flag._england:before {
  background-position: 0 -8064px;
}
.icon-intlpn-flag._gosquared:before {
  background-position: 0 -8096px;
}
.icon-intlpn-flag._kosovo:before {
  background-position: 0 -8128px;
}
.icon-intlpn-flag._mars:before {
  background-position: 0 -8160px;
}
.icon-intlpn-flag._nagorno-karabakh:before {
  background-position: 0 -8192px;
}
.icon-intlpn-flag._nato:before {
  background-position: 0 -8224px;
}
.icon-intlpn-flag._northern-cyprus:before {
  background-position: 0 -8256px;
}
.icon-intlpn-flag._olympics:before {
  background-position: 0 -8288px;
}
.icon-intlpn-flag._red-cross:before {
  background-position: 0 -8320px;
}
.icon-intlpn-flag._scotland:before {
  background-position: 0 -8352px;
}
.icon-intlpn-flag._somaliland:before {
  background-position: 0 -8384px;
}
.icon-intlpn-flag._south-ossetia:before {
  background-position: 0 -8416px;
}
.icon-intlpn-flag._united-nations:before {
  background-position: 0 -8448px;
}
.icon-intlpn-flag._unknown:before {
  background-position: 0 -8480px;
}
.icon-intlpn-flag._wales:before {
  background-position: 0 -8512px;
}
.form > *:first-child {
  border-radius: 4px 4px 0 0;
}
.form > *:last-child {
  border-radius: 0 0 4px 4px;
}
.form > *:first-child:last-child {
  border-radius: 4px;
}
.form-fieldset {
  margin: -1px;
  padding: 20px;
  background-color: #fafafa;
  border: 1px solid #e5e5e5;
  border-bottom-width: 3px;
  text-align: left;
}
.form-fieldset > li {
  display: table;
  width: 100%;
  max-width: 100%;
  table-layout: fixed;
}
.form-fieldset > li > div {
  display: table-cell;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
  vertical-align: top;
}
.form-fieldset > li > div:nth-of-type(1):nth-last-of-type(2),
.form-fieldset > li > div:nth-of-type(2):nth-last-of-type(1) {
  width: 50%;
}
.form-fieldset > li > div:nth-of-type(1):nth-last-of-type(4),
.form-fieldset > li > div:nth-of-type(2):nth-last-of-type(3),
.form-fieldset > li > div:nth-of-type(3):nth-last-of-type(2),
.form-fieldset > li > div:nth-of-type(4):nth-last-of-type(1) {
  width: 25%;
}

.form-fieldset-warehouses ul {
  margin: 0 -20px !important;
}

.grid-list {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  padding: 20px;
  box-sizing: border-box;
}
.grid-list > div {
  padding: 20px;
  width: 33.33%;
  box-sizing: border-box;
}
.grid-more {
  padding: 20px;
}
.grid.fullscreen .grid-list > div {
  width: 25%;
}
.icon-keypoints-template {
  background-image: url("assets/images/sprite.svg");
  background-repeat: no-repeat;
  background-size: 400px 200px;
  background-position: -112px -152px;
  width: 48px;
  height: 48px;
}
.icon-keypoints-world {
  background-image: url("assets/images/sprite.svg");
  background-repeat: no-repeat;
  background-size: 400px 200px;
  background-position: -160px -152px;
  width: 48px;
  height: 48px;
}
.icon-keypoints-sale {
  background-image: url("assets/images/sprite.svg");
  background-repeat: no-repeat;
  background-size: 400px 200px;
  background-position: -208px -152px;
  width: 48px;
  height: 48px;
}
.icon-keypoints-profile {
  background-image: url("assets/images/sprite.svg");
  background-repeat: no-repeat;
  background-size: 400px 200px;
  background-position: -256px -152px;
  width: 48px;
  height: 48px;
}
.icon-keypoints-find {
  background-image: url("assets/images/sprite.svg");
  background-repeat: no-repeat;
  background-size: 400px 200px;
  background-position: -304px -152px;
  width: 48px;
  height: 48px;
}
.icon-keypoints-business {
  background-image: url("assets/images/sprite.svg");
  background-repeat: no-repeat;
  background-size: 400px 200px;
  background-position: -352px -152px;
  width: 48px;
  height: 48px;
}
.icon-64-plus {
  background-image: url("assets/images/sprite.svg");
  background-repeat: no-repeat;
  background-size: 400px 200px;
  background-position: 0 -80px;
  width: 64px;
  height: 64px;
}
.icon-16-clock {
  display: inline-block;
  margin-right: 2px;
  vertical-align: sub;
  background-image: url("assets/images/sprite.svg");
  background-repeat: no-repeat;
  background-size: 400px 200px;
  background-position: -256px -64px;
  width: 16px;
  height: 16px;
}
.icon-16-tag {
  display: inline-block;
  margin-right: 2px;
  vertical-align: sub;
  background-image: url("assets/images/sprite.svg");
  background-repeat: no-repeat;
  background-size: 400px 200px;
  background-position: -272px -64px;
  width: 16px;
  height: 16px;
}
.icon-16-arrow-down {
  background-image: url("assets/images/sprite.svg");
  background-repeat: no-repeat;
  background-size: 400px 200px;
  background-position: -368px -72px;
  width: 16px;
  height: 16px;
}
.icon-16-arrow-up {
  background-image: url("assets/images/sprite.svg");
  background-repeat: no-repeat;
  background-size: 400px 200px;
  background-position: -384px -72px;
  width: 16px;
  height: 16px;
}
.icon-24-offer {
  background-image: url("assets/images/sprite.svg");
  background-repeat: no-repeat;
  background-size: 400px 200px;
  background-position: -328px 0;
  width: 24px;
  height: 24px;
}
.icon-24-message {
  background-image: url("assets/images/sprite.svg");
  background-repeat: no-repeat;
  background-size: 400px 200px;
  background-position: -352px 0;
  width: 24px;
  height: 24px;
}
.icon-24-edit {
  background-image: url("assets/images/sprite.svg");
  background-repeat: no-repeat;
  background-size: 400px 200px;
  background-position: -288px -112px;
  width: 24px;
  height: 24px;
}
.icon-24-delete {
  background-image: url("assets/images/sprite.svg");
  background-repeat: no-repeat;
  background-size: 400px 200px;
  background-position: -312px -112px;
  width: 24px;
  height: 24px;
}
.icon-link-go-to {
  display: inline-block;
  margin-left: 5px;
  vertical-align: middle;
  background-image: url("assets/images/sprite.svg");
  background-repeat: no-repeat;
  background-size: 400px 200px;
  background-position: -168px -88px;
  width: 18px;
  height: 14px;
}
.icon-link-file {
  background-image: url("assets/images/sprite.svg");
  background-repeat: no-repeat;
  background-size: 400px 200px;
  background-position: -96px -88px;
  width: 18px;
  height: 24px;
}
.icon-link-external {
  background-image: url("assets/images/sprite.svg");
  background-repeat: no-repeat;
  background-size: 400px 200px;
  background-position: -120px -88px;
  width: 20px;
  height: 24px;
}
.icon-link-delete-grey-lighter {
  background-image: url("assets/images/sprite.svg");
  background-repeat: no-repeat;
  background-size: 400px 200px;
  background-position: -72px -88px;
  width: 20px;
  height: 24px;
}
.icon-action-message {
  background-image: url("assets/images/sprite.svg");
  background-repeat: no-repeat;
  background-size: 400px 200px;
  background-position: -32px -40px;
  width: 24px;
  height: 24px;
}
.icon-action-negociate {
  background-image: url("assets/images/sprite.svg");
  background-repeat: no-repeat;
  background-size: 400px 200px;
  background-position: -64px -40px;
  width: 24px;
  height: 24px;
}
.icon-action-accept {
  background-image: url("assets/images/sprite.svg");
  background-repeat: no-repeat;
  background-size: 400px 200px;
  background-position: -96px -40px;
  width: 24px;
  height: 24px;
}
.icon-action-reject {
  background-image: url("assets/images/sprite.svg");
  background-repeat: no-repeat;
  background-size: 400px 200px;
  background-position: -128px -40px;
  width: 24px;
  height: 24px;
}
.icon-button-back {
  background-image: url("assets/images/sprite.svg");
  background-repeat: no-repeat;
  background-size: 400px 200px;
  background-position: -296px -32px;
  width: 9px;
  height: 24px;
}
.icon-button-check {
  background-image: url("assets/images/sprite.svg");
  background-repeat: no-repeat;
  background-size: 400px 200px;
  background-position: -224px -112px;
  width: 24px;
  height: 24px;
}
.icon-button-x {
  background-image: url("assets/images/sprite.svg");
  background-repeat: no-repeat;
  background-size: 400px 200px;
  background-position: -224px -88px;
  width: 24px;
  height: 24px;
}
.icon-list-url {
  background-image: url("assets/images/sprite.svg");
  background-repeat: no-repeat;
  background-size: 400px 200px;
  background-position: -192px -88px;
  width: 24px;
  height: 12px;
}
.icon-list-action {
  background-image: url("assets/images/sprite.svg");
  background-repeat: no-repeat;
  background-size: 400px 200px;
  background-position: -376px -88px;
  width: 24px;
  height: 24px;
}
.icon-upload-drop {
  background-image: url("assets/images/sprite.svg");
  background-repeat: no-repeat;
  background-size: 400px 200px;
  background-position: -64px -152px;
  width: 48px;
  height: 48px;
}
.icon-modal-close {
  background-image: url("assets/images/sprite.svg");
  background-repeat: no-repeat;
  background-size: 400px 200px;
  background-position: -352px -56px;
  width: 16px;
  height: 16px;
}
.icon-empty {
  display: block;
  margin: 0 auto 20px auto;
  background-image: url("assets/images/sprite.svg");
  background-repeat: no-repeat;
  background-size: 400px 200px;
  background-position: -16px -96px;
  width: 48px;
  height: 48px;
}
.icon-success .icon-16-tag {
  background-image: url("assets/images/sprite.svg");
  background-repeat: no-repeat;
  background-size: 400px 200px;
  background-position: -288px -64px;
  width: 16px;
  height: 16px;
}
.icon-danger .icon-16-tag {
  background-image: url("assets/images/sprite.svg");
  background-repeat: no-repeat;
  background-size: 400px 200px;
  background-position: -304px -64px;
  width: 16px;
  height: 16px;
}
.list {
  border: 1px solid #e5e5e5;
  font-family: "Lato", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  color: #666;
}
.list-options {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e6e6e6;
  border-bottom: 3px solid #e6e6e6;
  border-radius: 4px 4px 0 0;
}
.list-item {
  display: flex;
  align-items: center;
  border-top: 1px solid #e5e5e5;
  will-change: background-color;
  -webkit-transition: background-color 400ms ease;
  -moz-transition: background-color 400ms ease;
  transition: background-color 400ms ease;
  /*
      .align {
        &-top {}
        &-middle {}
        &-bottom {}
        &-left {}
        &-center {}
        &-right {}
      }
      */
}
.list-item:first-child {
  border-top: 0;
  border-radius: 4px 4px 0 0;
}
.list-item > div {
  padding: 15px;
}
.list-item strong {
  font-size: 16px;
  font-weight: 400;
  color: #444;
}
.list-item .action a {
  display: block;
}
.list-item .expand {
  flex: 1;
}
.list-more {
  padding-top: 40px;
  text-align: center;
}
.align-left {
  text-align: left;
}
.align-center {
  text-align: center;
}
.align-right {
  text-align: right;
}
.txt-uppercase {
  text-transform: uppercase;
  letter-spacing: 1px;
}
.txt-primary {
  color: #c19141;
}
.txt-info {
  color: #2980b9;
}
.txt-success {
  color: #27ae60;
}
.txt-danger {
  color: #c0392b;
}
.txt-black {
  color: #000;
}
.txt-grey {
  color: #666;
}
.txt-grey-darker {
  color: #222;
}
.txt-grey-dark {
  color: #444;
}
.txt-grey-light {
  color: #888;
}
.txt-grey-lighter {
  color: #aaa;
}
.txt-white {
  color: #fff;
}
.txt-transparent {
  color: transparent;
}
.bg-transparent {
  background-color: transparent;
}
.bg-primary {
  background-color: #c19141;
}
.bg-info {
  background-color: #2980b9;
}
.bg-success {
  background-color: #27ae60;
}
.bg-danger {
  background-color: #c0392b;
}
.bg-danger-light {
  background-color: #f5d5d2;
  border-bottom: 1px solid #f0c1bc;
}
.sticky-top {
  width: inherit;
  position: fixed;
  top: 0;
}
.dash:before,
.dash:after {
  content: none;
  display: block;
  margin: 0 auto;
  width: 48px;
  height: 2px;
  background-color: #ddd;
}
.dash:before {
  margin-bottom: 20px;
}
.dash:after {
  margin-top: 20px;
}
.dash-before:before {
  content: "";
}
.dash-after:after {
  content: "";
}
.dash-left:before,
.dash-left:after {
  margin-left: 0;
}
.dash-right:before,
.dash-right:after {
  margin-right: 0;
}
.dash-large:after {
  width: 64px;
}
.dash-primary:before,
.dash-primary:after {
  background-color: #c19141;
}
.dash-white:before,
.dash-white:after {
  background-color: #fff;
}
.full-width {
  width: 100%;
}
.no-wrap {
  white-space: nowrap;
}
.ellipsis {
  overflow: hidden;
  display: block;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.nested-view a.back {
  display: inline-block;
  margin-bottom: 5px;
  text-transform: uppercase;
  letter-spacing: 1px;
  will-change: color;
  font-family: "Lato", "Arial", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #aaa;
  -webkit-transition: color 400ms ease;
  -moz-transition: color 400ms ease;
  transition: color 400ms ease;
}
.nested-view a.back:hover {
  color: #888;
}
.nested-view-loader {
  height: 80px;
}
.nested-view-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
}
.nested-view-header > div {
  padding: 0 20px;
}
.nested-view-header h1 {
  font-family: "Lato", "Arial", sans-serif;
  font-size: 32px;
  font-weight: 300;
  line-height: normal;
  color: #222;
}
.nested-view-header p {
  padding-top: 10px;
  width: 100%;
  max-width: 960px;
  font-family: "Lato", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #888;
}
.nested-view-inner {
  padding: 20px 40px;
  width: 100%;
  box-sizing: border-box;
  text-align: left;
}
.nested-view .form {
  padding: 20px;
}
.nested-view .form-header + .form-body {
  margin-top: 0;
}
.nested-view .form-body {
  margin: 20px 20px 0 20px;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
}
.offer-grid {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  /*
      & > div {
        padding: 20px;
        width: 33.33%;
        box-sizing: border-box;
      }
      */
}
.offer-grid .offer-item {
  padding: 20px;
  width: 33.33%;
}
.offer-list {
  font-family: "Lato", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  color: #666;
}
.offer-list .offer-item:not(:first-child) {
  margin-top: 20px;
}
.offer-item {
  display: flex;
  align-items: center;
  padding: 20px 10px;
  margin: -1px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-family: "Lato", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  color: #666;
}
.offer-item.new {
  padding-left: 7px;
  border-left: 4px solid #c19141;
}
.offer-item > div {
  margin: 0 10px;
}
.offer-item:hover {
  cursor: pointer;
}
.offer-item .expand {
  flex: 1;
}
.offer-item-info a {
  color: #222;
  will-change: color;
  -webkit-transition: color 400ms ease;
  -moz-transition: color 400ms ease;
  transition: color 400ms ease;
}
.offer-item-info a:hover {
  color: #c19141;
}
.offer-item-tags {
  padding-top: 10px;
  font-family: "Lato", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: #aaa;
}
.offer-item-tags span {
  line-height: inherit;
}
.offer-item-tags > span:not(:first-child) {
  margin-left: 20px;
}
.offer-item-body {
  padding: 10px 20px;
  background-color: #fff;
  border-left: 1px solid #e7e9ea;
  border-right: 1px solid #e7e9ea;
}
.offer-item-body > div {
  display: flex;
  padding: 10px 0;
  border-top: 1px solid #e7e9ea;
}
.offer-item-body > div:first-child {
  border-top: none;
}
.offer-item-body > div strong {
  color: #444;
}
.offer-item-body > div .expand {
  flex: 1;
}
.offer-item-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #e7e9ea;
  border-radius: 0 0 4px 4px;
}
.offer-summary {
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  font-family: "Lato", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  color: #666;
}
.offer-summary-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: -1px;
  padding: 20px 10px;
  background-color: #fbfbfb;
  border: 1px solid #e5e5e5;
  border-radius: 4px 4px 0 0;
}
.offer-summary-header strong,
.offer-summary-header a {
  margin: 0 10px;
}
.offer-summary-header strong {
  font-weight: inherit;
}
.offer-summary-header-img {
  overflow: hidden;
  padding-top: 75%;
  width: 100%;
  background-color: #fff;
  border-radius: 4px 4px 0 0;
  box-sizing: border-box;
  position: relative;
}
.offer-summary-header-img:before {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 4px 4px 0 0;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
.offer-summary-header-img .inner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  -webkit-transform: translate3d(-50%, -50%, 0);
  -moz-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
}
.offer-summary-header-img img {
  width: 100%;
  height: auto;
  opacity: 0;
  will-change: opacity;
  -webkit-transition: opacity 400ms ease;
  -moz-transition: opacity 400ms ease;
  transition: opacity 400ms ease;
}
.offer-summary-header-img img.loaded {
  opacity: 1;
}
.offer-summary-body {
  padding: 0 20px;
}
.offer-summary-body > li {
  display: flex;
  padding: 20px 0;
  border-top: 1px solid #e5e5e5;
}
.offer-summary-body > li:first-child {
  border-top: 0;
}
.offer-summary-body > li strong {
  color: #444;
}
.offer-summary-body > li .expand {
  flex: 1;
}
.offer-summary-footer {
  padding: 20px;
}
.offer-summary-footer .button {
  margin-top: 10px;
}
.offer-summary-footer .button:first-child {
  margin-top: 0;
}
.product-list > li {
  margin: -1px;
  background-color: #fff;
  border: 1px solid #e5e5e5;
}
.product-list > li:first-child {
  border-radius: 4px 4px 0 0;
}
.product-list > li:last-child {
  border-radius: 0 0 4px 4px;
}
.product-item {
  display: flex;
  padding: 10px;
}
.product-item > div {
  padding: 10px;
}
.product-item .selection {
  padding: 14px 10px;
}
.product-item .content {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.product-sku {
  font-size: 12px;
  color: #aaa;
}
.product-name {
  font-size: 18px;
  font-weight: inherit;
  color: #444;
}
.product-name.icon {
  align-items: baseline;
  will-change: color;
  -webkit-transition: color 300ms ease;
  -moz-transition: color 300ms ease;
  transition: color 300ms ease;
}
.product-name.icon:hover {
  color: #c19141;
}
.product-highlights {
  display: flex;
  padding-top: 20px;
  color: #444;
}
.product-highlights small {
  font-size: 14px;
  color: #aaa;
}
.product-highlights > div {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
}
.product-highlights > div:first-child {
  padding-left: 0;
}
.product-form {
  display: flex;
}
.product-form > div {
  width: 100px;
  padding-left: 20px;
}
.product-form > div:first-child {
  padding-left: 0;
}
.product-more {
  padding: 0 20px 20px 66px;
}
.product-more > a {
  font-size: 14px;
}
.product-info {
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  font-size: 14px;
  color: #666;
}
.product-info small {
  font-size: 14px;
  color: #aaa;
}
.product-info > div {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
}
.product-info > div:first-child {
  padding-top: 0;
}
.data-table {
  display: table;
  width: 100%;
  text-align: left;
  vertical-align: middle;
  font-family: "Lato", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  color: #666;
}
.data-table > div {
  display: table-row;
  /*
      &:first-child {
        & > div {
          &:first-child {
            border-radius: 4px 0 0 0;
          }

          &:last-child {
            border-radius: 0 4px 0 0;
          }
        }
      }

      &:not(:first-child) {
        & > div {
          border-top: 0;
        }
      }

      &:last-child {
        & > div {
          &:first-child {
            border-radius: 0 0 0 4px;
          }

          &:last-child {
            border-radius: 0 0 4px 0;
          }
        }
      }

      &:first-child,
      &:last-child {
        & > div {
          &:first-child {
            border-radius: 4px 0 0 4px;
          }

          &:last-child {
            border-radius: 0 4px 4px 0;
          }

          &:first-child:last-child {
            border-radius: 4px;
          }
        }
      }
      */
}
.data-table > div > div {
  display: table-cell;
  padding: 20px;
  border: 1px solid #e5e5e5;
  vertical-align: middle;
}
.data-table > div > div:not(:first-child) {
  border-left: 0;
}
.data-table > div.data-table-header {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: "Lato", "Arial", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  color: #888;
}
.data-table > div.data-table-header > div {
  background-color: #f5f5f5;
}
.data-table > div.data-table-empty > div {
  padding: 40px;
  background-color: #f5f5f5;
  text-align: center;
}
.data-table.v-align-top > div > div {
  vertical-align: top;
}
.data-table.v-align-middle > div > div {
  vertical-align: middle;
}
.data-table.has-error > div > div {
  border-color: rgba(255,0,0,0.25);
}
.data-table.has-error > div.data-table-header > div {
  background-color: rgba(255,0,0,0.1);
}
.data-table .checkbox {
  margin-top: 5px;
}
.data-table-column-expand {
  width: 100%;
}
.data-table-column-fit {
  width: 1px;
  white-space: nowrap;
}
.view-steps {
  padding: 30px 0;
  background-color: #f9f9f9;
  border-bottom: 1px solid #eee;
}
.view-inner {
  margin: 0 auto;
  padding: 60px 0;
  max-width: 1280px;
  box-sizing: border-box;
}
.view .section-header,
.view .section-content,
.view .section-action,
.view .section-form {
  margin: 0 auto;
  box-sizing: border-box;
  text-align: center;
}
.view .section-header.max-width,
.view .section-content.max-width,
.view .section-action.max-width,
.view .section-form.max-width {
  max-width: 1280px;
}
.view .section-header.no-max-width,
.view .section-content.no-max-width,
.view .section-action.no-max-width,
.view .section-form.no-max-width {
  max-width: unset;
}
.view .section-header,
.view .section-content,
.view .section-action {
  padding: 20px 40px;
  text-align: center;
}
.view .section-header h1,
.view .section .highlight h1 {
  padding-bottom: 20px;
  color: #444;
}
.view .section-header p,
.view .section .highlight p {
  font-size: 18px;
  line-height: 27px;
  color: #888;
}
.view .section-content h2 + div p:first-of-type {
  padding-top: 20px;
}
.view .section-action {
  display: flex;
  justify-content: center;
  min-height: 88px;
  position: relative;
}
.view .section-action .loader {
  height: 48px;
  position: absolute;
  top: 20px;
  left: 0;
  z-index: 1;
}
.view .section-action .button {
  margin: 0 10px;
}
.view .section-form {
  padding: 0 20px;
  text-align: left;
}
.view .section-inner {
  margin: 0 auto;
  padding: 60px 0;
  max-width: 960px;
  box-sizing: border-box;
}
.view .section-bg {
  width: 100%;
  height: 100%;
  opacity: 0;
  will-change: opacity;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  -webkit-transition: opacity 400ms ease;
  -moz-transition: opacity 400ms ease;
  transition: opacity 400ms ease;
}
.view .section-bg.loaded {
  opacity: 1;
}
.view .content p {
  margin: 0 auto;
  width: 100%;
  max-width: 960px;
  text-align: center;
  font-family: "Lato", "Arial", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 32px;
  color: #888;
}
.about-etailers {
  background-color: #f5f5f5;
}
.about-etailers .section-content {
  padding: 20px 20px 0 20px;
}
.about-etailers-grid {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.about-etailers-grid > div {
  padding: 20px;
  width: 33.33%;
  box-sizing: border-box;
}
.about-team .section-content {
  padding: 20px 20px 0 20px;
}
.about-team-grid {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.about-team-grid > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: 33.33%;
  box-sizing: border-box;
  /*
          &:nth-child(1) {
            padding-left: calc((33.33% + 20px) / 2);
            width: 50%;
          }

          &:nth-child(2) {
            padding-right: calc((33.33% + 20px) / 2);
            width: 50%;
          }
            */
}
.about-team-grid .avatar {
  margin-bottom: 20px;
}
.about-team-grid strong {
  color: #444;
}
.about-team-grid a {
  margin-top: 5px;
}
.account-brand .tab-test .tab-bar {
  padding-top: 0;
  background-color: #fff;
}
.account-brand .material-list {
  position: relative;
}
.account-brand .material-list .drag {
  width: 32px;
  height: 32px;
  background-color: #00f;
  position: absolute;
  top: 50%;
  left: -16px;
  -webkit-transform: translate3d(0, -50%, 0);
  -moz-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0);
}
.account-brand .material-list .remove {
  width: 32px;
  height: 32px;
  background-color: #f00;
  position: absolute;
  top: 50%;
  right: -16px;
  -webkit-transform: translate3d(0, -50%, 0);
  -moz-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0);
}
.account-brand .parser-error-table {
  display: flex;
  flex-direction: column;
  text-align: center;
  /*


      &-body {
        & > div {
          margin: -1px;
          padding: 10px 20px;
          background-color: $grey_e5;
          border: 1px solid $grey_dd;

          &:first-child {
            border-radius: 4px 0 0 0;
          }

          &:last-child {
            flex: 1;
            border-radius: 0 4px 0 0;
          }
        }


        display: flex;
        flex-direction: column;
        width: 100%;
        background-color: $white;
        set-font($lato, 14px, 400, normal, $grey_light); // font-size ?
      }
      */
}
.account-brand .parser-error-table-body {
  background-color: #fff;
  font-family: "Lato", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  color: #888;
}
.account-brand .parser-error-table .row {
  display: flex;
  width: 100%;
}
.account-brand .parser-error-table .row-header {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: "Lato", "Arial", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  color: #888;
}
.account-brand .parser-error-table .row-header > div {
  background-color: #e5e5e5;
}
.account-brand .parser-error-table .row-header > div:first-child {
  border-radius: 4px 0 0 0;
}
.account-brand .parser-error-table .row-header > div:last-child {
  border-radius: 0 4px 0 0;
}
.account-brand .parser-error-table .col {
  display: flex;
  align-self: stretch;
  margin-top: -1px;
  margin-right: -1px;
  border: 1px solid #ddd;
  box-sizing: border-box;
}
.account-brand .parser-error-table .col-line,
.account-brand .parser-error-table .col-error {
  padding: 20px;
}
.account-brand .parser-error-table .col-line {
  justify-content: flex-end;
  width: 120px;
  margin-left: -1px;
  text-align: right;
}
.account-brand .parser-error-table .col-error {
  flex: 1;
  text-align: left;
}
.account-brand .column {
  display: flex;
  width: 100%;
  padding-bottom: 20px;
}
.account-brand .column-left {
  flex: 1;
}
.account-brand .column-right {
  width: 280px;
  margin-right: 40px;
}
.account-brand .column-right > div {
  margin-top: 20px;
}
.account-brand .spreadsheet-drop {
  padding-top: 0;
}
.account-brand .spreadsheet-drop .loader {
  padding-top: 40px;
}
.account-brand .spreadsheet-drop .loader span {
  background-color: #c19141;
}
.account-company .tab-test .tab-bar {
  padding-top: 0;
  background-color: #fff;
}
.account-company .section-content.team-grid {
  padding: 0;
}
.account-company .member-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 20px;
}
.account-company .member-grid > div {
  padding: 20px;
  width: 100%;
  max-width: 33.33%;
  box-sizing: border-box;
}
.account-company .member-card {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.account-company .member-card > div:first-child {
  border-radius: 4px 4px 0 0;
}
.account-company .member-card > div:last-child {
  border-radius: 0 0 4px 4px;
}
.account-company .member-card > div:first-child:last-child {
  border-radius: 4px;
}
.account-company .member-card-body {
  flex: 1;
  margin: -1px;
  padding: 40px 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
}
.account-company .member-card-body > * {
  display: block;
}
.account-company .member-card-body .avatar {
  margin: 0 auto 20px auto;
}
.account-company .member-card-body strong {
  color: #444;
}
.account-company .member-card-body a {
  margin-top: 10px;
}
.account-company .member-card-footer {
  display: flex;
  justify-content: flex-end;
  margin: -1px;
  background-color: #eee;
  border: 1px solid #ddd;
  border-radius: 4px;
}
.account-company .member-card-footer a {
  width: 48px;
  height: 48px;
  background-color: transparent;
  border-left: 1px solid #ddd;
}
.account-company .warehouse-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 20px;
}
.account-company .warehouse-grid > div {
  padding: 20px;
  width: 100%;
  max-width: 33.33%;
  box-sizing: border-box;
}
.account-company .warehouse-card {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.account-company .warehouse-card > div:first-child {
  border-radius: 4px 4px 0 0;
}
.account-company .warehouse-card > div:last-child {
  border-radius: 0 0 4px 4px;
}
.account-company .warehouse-card > div:first-child:last-child {
  border-radius: 4px;
}
.account-company .warehouse-card-body {
  flex: 1;
  margin: -1px;
  padding: 40px 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
}
.account-company .warehouse-card-body > * {
  display: block;
}
.account-company .warehouse-card-body strong {
  color: #666;
}
.account-company .warehouse-card-footer {
  display: flex;
  justify-content: flex-end;
  margin: -1px;
  background-color: #eee;
  border: 1px solid #ddd;
  border-radius: 4px;
}
.account-company .warehouse-card-footer a {
  width: 48px;
  height: 48px;
  background-color: transparent;
  border-left: 1px solid #ddd;
}
.account-company .warehouse-new-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  height: 100%;
  background-color: rgba(193,145,65,0.3);
  border: 1px solid rgba(193,145,65,0.3);
  border-radius: 4px;
  box-sizing: border-box;
  color: rgba(0,0,0,0.4);
  text-align: center;
  cursor: pointer;
}
.account-company .warehouse-new-card .icon-64-plus {
  margin-bottom: 20px;
  opacity: 0.3;
}
.account-company .logo-upload {
  padding-top: 0;
}
.account-company address {
  color: #888;
}
.account-company address strong {
  padding-bottom: 4px;
  color: #666;
}
.account-dashboard .tab-test .tab-bar {
  padding-top: 0;
  background-color: #fff;
}
.account-me-profile {
  padding: 0 20px;
}
.account-message .tab-test .tab-bar {
  padding-top: 0;
  background-color: #fff;
}
.account-message .message-item {
  display: flex;
  margin: -1px;
  padding: 12px;
  background-color: #fff;
  border: 1px solid #ddd;
  will-change: background-color;
  -webkit-transition: background-color 400ms linear;
  -moz-transition: background-color 400ms linear;
  transition: background-color 400ms linear;
}
.account-message .message-item:first-child {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.account-message .message-item:last-child {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
.account-message .message-item:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}
.account-message .message-item > div {
  padding: 12px;
}
.account-message .message-item .item-avatar {
  position: relative;
}
.account-message .message-item .unread-indicator {
  width: 16px;
  height: 16px;
  background-color: #c19141;
  border: 2px solid #fff;
  border-radius: 50%;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 4px;
  z-index: 2;
  -webkit-transform: translate3d(0, -50%, 0);
  -moz-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0);
}
.account-message .message-item .item-body {
  flex: 1;
  min-width: 0;
  text-align: left;
}
.account-message .message-item .item-body strong {
  color: #666;
}
.account-message .message-item .item-body p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.account-offer .tab-test .tab-bar {
  padding-top: 0;
  background-color: #fff;
}
.account-offer .section-inner {
  max-width: 1280px;
}
.account-offer .column {
  display: flex;
  width: 100%;
  padding-bottom: 20px;
}
.account-offer .column-left {
  flex: 1;
  padding: 0 40px;
}
.account-offer .column-right {
  width: 280px;
  margin-right: 40px;
}
.account-offer .column-right > div {
  margin-top: 20px;
}
.account-offer .message-item {
  display: flex;
  margin: -1px;
  padding: 12px;
  background-color: #fff;
  border: 1px solid #ddd;
  will-change: background-color;
  -webkit-transition: background-color 400ms linear;
  -moz-transition: background-color 400ms linear;
  transition: background-color 400ms linear;
  /*
      &:hover {
        background-color: #F5F5F5;
        cursor: pointer;
      }
      */
}
.account-offer .message-item:first-child {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.account-offer .message-item:last-child {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
.account-offer .message-item > div {
  padding: 12px;
}
.account-offer .message-item .item-avatar {
  position: relative;
}
.account-offer .message-item .unread-indicator {
  width: 16px;
  height: 16px;
  background-color: #c19141;
  border: 2px solid #fff;
  border-radius: 50%;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 4px;
  z-index: 2;
  -webkit-transform: translate3d(0, -50%, 0);
  -moz-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0);
}
.account-offer .message-item .item-body {
  flex: 1;
  min-width: 0;
  text-align: left;
  /*
        p {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        */
}
.account-offer .message-item .item-body strong {
  color: #666;
}
.account-offer .message-item.reverse {
  flex-direction: row-reverse;
}
.account-offer .message-item.reverse .item-body {
  text-align: right;
}
.account-offer .message-form {
  display: flex;
  margin: -1px;
  padding: 12px;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
.account-offer .message-form .form-body > div {
  padding: 12px;
}
.account-offer .message-form .form-body {
  flex: 1;
  display: flex;
}
.account-offer .message-form .form-body .form-body-message {
  flex: 1;
}
.account-offer .message-form button {
  padding: 9px 22px 9px 24px;
}
.account-profile section {
  padding: 0 20px;
  visibility: hidden;
}
.account-profile .nested-view-loader {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.account-profile .tab-bar {
  justify-content: flex-start;
}
.account-profile .tab-bar a:first-child {
  margin-left: 0;
}
.account-user .tab-test .tab-bar {
  padding-top: 0;
  background-color: #fff;
}
.account {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #fff;
}
.account .inner {
  display: flex;
  padding: 60px 0;
  width: 100%;
  max-width: 1280px;
  box-sizing: border-box;
}
.account .inner > div:first-child {
  width: 100%;
  max-width: 320px;
}
.account .inner > div:last-child {
  flex: 1;
}
.brands .hero {
  height: 110px;
  background-color: #252525;
  position: relative;
}
.brands .hero-cover {
  height: 400px;
}
.brands .hero-overlay {
  display: flex;
  min-width: 480px;
  max-width: 1200px;
  height: 288px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0 40px 0 rgba(0,0,0,0.4);
  position: absolute;
  top: calc(100% - 80px);
  left: 50%;
  z-index: 2;
  -webkit-transform: translate3d(-50%, 0, 0);
  -moz-transform: translate3d(-50%, 0, 0);
  transform: translate3d(-50%, 0, 0);
}
.brands .hero-overlay .brand {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  padding: 24px;
}
.brands .hero-overlay .brand-info {
  display: flex;
  align-items: center;
}
.brands .hero-overlay .brand-info h1 {
  margin-bottom: 8px;
  line-height: normal;
}
.brands .hero-overlay .brand-info .avatar {
  margin-right: 24px;
}
.brands .hero-overlay .brand-distributor small,
.brands .hero-overlay .brand-distributor a {
  display: block;
  line-height: normal;
}
.brands .hero-overlay .brand-distributor small {
  color: #aaa;
}
.brands .hero-overlay .brand-distributor a {
  font-size: 14px;
  color: #666;
}
.brands .hero-overlay .brand-actions {
  display: flex;
  justify-content: center;
}
.brands .hero-overlay .brand-actions .button:not(:first-child) {
  margin-left: 8px;
}
.brands .hero-overlay .brand-images {
  padding: 24px 8px 24px 24px;
  border-left: 1px solid #eee;
}
.brands .hero-overlay .brand-images .swiper-slide {
  max-width: 240px;
  width: 100% !important;
}
.brands .hero-overlay .brand-images img {
  display: block;
  margin: 0;
  width: 240px;
  height: 240px;
  border-radius: 2px;
}
.brands .hero-overlay-expand {
  width: 100%;
}
.brands .hero-overlay-expand .brand-actions {
  justify-content: flex-start;
}
.brands .tab-bar {
  padding-top: 248px;
}
.brands .column {
  display: flex;
  width: 100%;
  padding-bottom: 20px;
}
.brands .column-left {
  flex: 1;
  padding: 20px 40px 0 40px;
}
.brands .column-right {
  width: 280px;
  margin-right: 40px;
}
.brands .column-right > div {
  margin-top: 20px;
}
.brands .toggle-inner {
  flex-direction: column;
}
.brands .toggle-inner div:first-child {
  border-radius: 4px 4px 0 0;
}
.brands .toggle-inner div:last-child {
  border-radius: 0 0 4px 4px;
}
.brands .toggle-inner div.selected {
  background-color: #ddd;
}
.brands section.products h1 {
  padding: 20px 40px;
}
.brands .download-links {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 20px 20px 0 20px;
  width: 100%;
  box-sizing: border-box;
}
.brands .download-links a {
  margin: 0 20px;
}
.browse section.browse-brands {
  min-height: 100vh;
  position: relative;
}
.browse section.browse-brands .section-loader {
  background-color: rgba(255,255,255,0.8);
  opacity: 0;
  pointer-events: none;
  will-change: opacity;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  -webkit-transition: opacity 400ms ease 50ms;
  -moz-transition: opacity 400ms ease 50ms;
  transition: opacity 400ms ease 50ms;
}
.browse section.browse-brands .section-loader.show {
  opacity: 1;
  pointer-events: auto;
}
.browse section.browse-brands .section-inner {
  padding: 80px 0 60px 0;
}
.browse section.browse-brands h1 {
  padding-bottom: 60px;
}
.browse section.browse-brands .columns {
  display: flex;
  width: 100%;
}
.browse section.browse-brands .columns > div:first-child {
  width: 100%;
  max-width: 320px;
}
.browse section.browse-brands .columns > div:last-child {
  flex: 1;
}
.browse section.browse-brands .grid-list {
  padding-top: 0;
  padding-bottom: 0;
}
.browse section.browse-cta {
  background-color: #f5f5f5;
  background-image: linear-gradient(to bottom, rgba(175,131,57,0.2) 0%, transparent 50%);
  position: relative;
  z-index: 2;
}
.browse section.browse-cta h2 {
  padding-bottom: 20px;
}
.browse section.browse-cta .actions {
  display: flex;
  justify-content: center;
  margin-top: 60px;
}
.browse section.browse-cta .actions a {
  margin: 0 20px;
  width: 200px;
}
.browse.reveal section.browse-brands {
  min-height: auto;
}
/*.browse .section-inner h1,*/
/*.browse .section-inner h2,*/
/*.browse .section-inner .content,*/
/*.browse .section-inner .actions a,*/
/*.browse .filters,*/
/*.browse .brand-card,*/
/*.browse .grid-more {*/
/*  visibility: hidden;*/
/*}*/
.contact {
  margin-top: 140px;
}
.contact h2 {
  padding-bottom: 20px;
  text-align: center;
}
.contact h2:after {
  content: "";
  display: block;
  margin: 20px auto 0 auto;
  width: 64px;
  height: 4px;
  background-color: #ddd;
}
.contact p {
  margin: 0 auto;
  width: 100%;
  max-width: 960px;
  text-align: center;
  font-size: 18px;
  line-height: 32px;
  color: #888;
}
.contact .inner {
  padding: 80px 40px;
  width: 100%;
  max-width: 1280px;
  box-sizing: border-box;
  text-align: center;
}
.contact-intro {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
}
.dashboard section .columns {
  display: flex;
  width: 100%;
}
.dashboard section .columns > div:first-child {
  width: 100%;
  max-width: 320px;
}
.dashboard section .columns > div:last-child {
  flex: 1;
}
.etailers .hero {
  height: 110px;
  background-color: #252525;
  position: relative;
}
.etailers .hero-cover {
  height: 400px;
}
.etailers .hero-overlay {
  display: flex;
  min-width: 480px;
  max-width: 1200px;
  height: 288px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0 40px 0 rgba(0,0,0,0.4);
  position: absolute;
  top: calc(100% - 80px);
  left: 50%;
  z-index: 2;
  -webkit-transform: translate3d(-50%, 0, 0);
  -moz-transform: translate3d(-50%, 0, 0);
  transform: translate3d(-50%, 0, 0);
}
.etailers .hero-overlay .etailer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  padding: 24px;
}
.etailers .hero-overlay .etailer-info {
  display: flex;
  align-items: center;
}
.etailers .hero-overlay .etailer-info h1 {
  margin-bottom: 8px;
  line-height: normal;
}
.etailers .hero-overlay .etailer-info .avatar {
  margin-right: 24px;
}
.etailers .hero-overlay .etailer-distributor small,
.etailers .hero-overlay .etailer-distributor a {
  display: block;
  line-height: normal;
}
.etailers .hero-overlay .etailer-distributor small {
  color: #aaa;
}
.etailers .hero-overlay .etailer-distributor a {
  font-size: 14px;
  color: #666;
}
.etailers .hero-overlay .etailer-actions {
  display: flex;
  justify-content: center;
}
.etailers .hero-overlay .etailer-actions .button:not(:first-child) {
  margin-left: 8px;
}
.etailers .hero-overlay .etailer-images {
  padding: 24px 8px 24px 24px;
  border-left: 1px solid #eee;
}
.etailers .hero-overlay .etailer-images .swiper-slide {
  max-width: 240px;
  width: 100% !important;
}
.etailers .hero-overlay .etailer-images img {
  display: block;
  margin: 0;
  width: 240px;
  height: 240px;
  border-radius: 2px;
}
.etailers .hero-overlay-expand {
  width: 100%;
}
.etailers .hero-overlay-expand .etailer-actions {
  justify-content: flex-start;
}
.etailers .tab-bar {
  padding-top: 248px;
}
.etailers .column {
  display: flex;
  width: 100%;
  padding-bottom: 20px;
}
.etailers .column-left {
  flex: 1;
  padding: 20px 40px 0 40px;
}
.etailers .column-right {
  width: 280px;
  margin-right: 40px;
}
.etailers .column-right > div {
  margin-top: 20px;
}
.etailers section.products h1 {
  padding: 20px 40px;
}
.etailers .download-links {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 20px 20px 0 20px;
  width: 100%;
  box-sizing: border-box;
}
.etailers .download-links a {
  margin: 0 20px;
}
.features-details .section-content {
  padding: 20px 20px 0 20px;
}
.features-app {
  background-color: #151515;
  position: relative;
  z-index: 2;
}
.features-app h2 {
  color: #fff;
}
.features-app p {
  color: #aaa;
}
.features-app .section-inner {
  padding-bottom: 40px;
  position: relative;
  z-index: 2;
}
.features-cta {
  background-color: #f5f5f5;
  background-image: linear-gradient(to bottom, rgba(175,131,57,0.2) 0%, transparent 50%);
}
.features-cta .section-inner {
  padding-top: 137px;
}
.features .keypoints {
  display: flex;
  flex-wrap: wrap;
}
.features .keypoints > div {
  padding: 20px;
  width: 33.33%;
  box-sizing: border-box;
}
.features .keypoints strong {
  display: block;
  padding-bottom: 10px;
  color: #666;
}
.features .keypoints .icon {
  display: block;
  margin: 0 auto 20px auto;
}
.features .macbook {
  height: 514px;
  position: relative;
  z-index: 2;
}
.features .macbook .frame {
  margin: 0 auto;
  padding-top: 38px;
  padding-left: 125px;
  width: 1014px;
  height: 591px;
  background: url("assets/images/features-macbook.png") no-repeat top center;
  box-sizing: border-box;
}
.features .macbook div {
  width: 762px;
  height: 476px;
  background-color: #333;
}

.identification .form {
  max-width: 480px;
}
.identification .signup .section-header p:first-of-type,
.identification .login .section-header p:first-of-type,
.identification .forgot .section-header p:first-of-type,
.identification .resend .section-header p:first-of-type,
.identification .reset .section-header p:first-of-type {
  padding-top: 20px;
}
.identification .signup .form {
  max-width: 600px;
}

.preview-selected-image-wrap {
  padding-top: 40px;
}
.preview-selected-image {
  margin: 0 auto;
  width: 120px;
  position: relative;
}

.preview-selected-image > img {
  display: block;
  width: 120px;
  height: auto;
}

.preview-selected-image-close {
  position: absolute;
  width: 24px;
  height: 24px;
  top: -12px;
  right: -12px;
  z-index: 1;
  background-color: #151515;
  border-radius: 50%;
  color: white;
  text-align: center;
  cursor: pointer;
}

.link-btn {
  background: none;
  border: none;
  cursor: pointer;
}

.sr-root {

}

.sr-root h1 {
  text-align: center;
}

.price-table-container {
  text-align: center;
}

.price-table-container section {
  width: 50%;
  margin: 0 auto;
}

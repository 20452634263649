.product-card {
  width: 100%;
  height: 100%;
  text-align: left;
}
.product-card:hover {
  cursor: pointer;
}
.product-card-header {
  overflow: hidden;
  padding-top: 100%;
  width: 100%;
  background-color: #fff;
  border-radius: 4px 4px 0 0;
  box-sizing: border-box;
  position: relative;
}
.product-card-header:before {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 4px 4px 0 0;
  box-sizing: border-box;
  box-shadow: inset 0 0 0 1px rgba(0,0,0,0.14);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

.each-slide.no-image {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-top: 1px solid #ddd;
  border-radius: 4px 4px 0 0;
  box-sizing: border-box;
  background-color: #eee;
}

.product-card-footer {
  flex: 1;
  padding: 20px;
  width: 100%;
  /*min-height: 120px;*/
  background-color: #fff;
  border-top: 1px solid #ddd;
  /*border-left: 1px solid #ddd;*/
  /*border-right: 1px solid #ddd;*/
  /*border-bottom: 1px solid #ddd;*/
  border-radius: 0 0 4px 4px;
  box-sizing: border-box;
  text-align: center;
}

.product-card-footer > div {
  padding-top: 10px;
  font-size: 14px;
  line-height: 21px;
}
.product-card-footer > div small {
  font-size: 12px;
  font-weight: 300;
  line-height: 18px;
}
.product-card-footer > div a {
  display: block;
  color: #444;
}

.each-slide {
  display: flex;
  width: 100%;
  height: 250px;
}

.each-slide > div {
  width: 75%;
}

.each-slide > div img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.each-slide p {
  width: 25%;
  font-size: 1em;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: 0;
  background: #adceed;
}

.indicators {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  padding-top: 20px;
  margin-top: 0 !important;
}

.product-details {
  display: flex;
}

.product-details-info {
  margin-left: 40px;
  flex: 1;
}

.product-details-info .wholesale {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  flex: 1;
}

.product-details-info .wholesale small {
  line-height: 20px;
  color: #c19141;
}

.product-main-info {
  display: flex;
}

.action-wrap {
  display: flex;
  justify-content: center;
  padding: 40px;
  margin: 20px 0;
  background-color: #f8f8f8;
  border: 1px solid #ebebeb;
}

.react-slideshow-container > .nav {
  top: 120px;
}


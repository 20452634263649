.products h3 {
  text-align: center;
  margin-bottom: 16px;
}

.products .no-filters  {
  width: 70%;
  margin: 0 auto;
}

.product-list-grid {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1;
  margin: 0 -8px;
}

.product-list-grid > div {
  padding: 8px;
  width: 25%;
  box-sizing: border-box;
}

.product-card {
  border: 1px solid #C2CCD6;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
}

.product-card .product-header {
  width: 100%;
  height: 120px;
  overflow: hidden;
}

.product-card .product-header img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.product-card .product-body {
  padding: 8px;
  border-top: 1px solid #C2CCD6;
}

.product-card .product-body .product-name {
  color: #222222;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
}

.product-item .product-body .price {
  color: #222222;
  font-weight: 400;
  font-size: 12px;
}

.columns {
  display: flex;
  width: 100%;
}
 .columns > div:first-child {
   width: 100%;
   max-width: 320px;
   margin-right: 24px;
 }

.columns > div:last-child {
  flex: 1;
}

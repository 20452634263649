@import "~react-image-gallery/styles/css/image-gallery.css";

.view-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.view-content {
  flex: 1;
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 60px;
  width: 60px;
}

.image-gallery-icon:hover {
  color: #fff;
  opacity: 0.6;
}

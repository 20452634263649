.home {
  flex: 1;
}

.home .view-inner {
  padding: 4px 0;
}

.home h3 {
  text-align: center;
  margin-bottom: 12px;
}

.home section.home-page {
  display: flex;
}

.home .column {
  padding: 12px;
}

.home .column-left, .column-right {
  width: 25%;
}

.home .column-middle {
  width: 50%;
}

.home .block {
  margin-bottom: 24px;
  padding: 24px;
  border: 1px solid #C2CCD6;
  border-radius: 24px;
}

.home .block h4 {
  margin-bottom: 16px;
  text-align: center;
  color: #222222;
}

.home .brand-offers-counters {
  margin-top: 16px;
}

.home .brand-offers-counters h4 {
  margin-bottom: 8px;
}

.home .brand-offers-count {
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  color: #222222;
}

.home .brand-offers-count-title {
  margin-right: 4px;
  color: #393939;
}

.brand {
  width: 100%;
  margin-bottom: 16px;
  border: 1px solid #C2CCD6;
  border-radius: 2px;
  cursor: pointer;
  overflow: hidden;
}

.brand .brand-header img {
  max-width: 100%;
}

.brand .brand-header.no-logo {
  background-color: black;
  padding: 24px;
}

.logo-placeholder {
  overflow: hidden;
  display: block;
  text-indent: -9999px;
  background-image: url("../../assets/images/sprite.svg");
  background-repeat: no-repeat;
  background-size: 400px 200px;
  background-position: 0 0;
  width: 156px;
  height: 26px;
}

.brand .brand-header.brand-header-medium {
  width: 100%;
  height: 164px;
  overflow:hidden;
}

.brand .brand-header.brand-header-medium > img {
  width:100%;
  height: 100%;
  object-fit: cover;
}

.brand .brand-body {
  padding: 8px;
  border-top: 1px solid #C2CCD6;
}

.brand .brand-body .brand-name {
  color: #222222;
  font-weight: 700;
  font-size: 14px;
}

.brand .brand-body .brand-company-name {
  font-weight: 400;
  font-size: 14px;
  color: #393939;
}

.brand .brand-body small {
  font-weight: 400;
  font-size: 12px;
  color: #A5A5A5;
}

.brand .brand-footer {
  padding: 8px;
  background: #F3F5F7;
  border-radius: 0 0 2px 2px;
  border-top: 1px solid #C2CCD6;
}

.show-more-btn {
  color: #222222;
  font-weight: 700;
  font-size: 14px;
  display: flex;
}

.arrow-forward-icon {
  background: url(../../assets/icons/arrow-forward.svg) no-repeat;
  width: 24px;
  height: 24px;
  margin-left: 4px;
}

.product-grid {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1;
  margin: 0 -8px;
}

.product-grid > div {
  padding: 8px;
  width: 50%;
  box-sizing: border-box;
}

.product {
  border: 1px solid #C2CCD6;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
}

.product .product-header {
  width: 100%;
  height: 120px;
  overflow: hidden;
}

.product .product-header img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.product .product-body {
  padding: 8px;
  border-top: 1px solid #C2CCD6;
}

.product .product-body .product-name {
  color: #222222;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
}

.product .product-body .price {
  color: #222222;
  font-weight: 400;
  font-size: 12px;
}

.get-started-card {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #222222;
  text-align: center;
  cursor: pointer;
}

/* Index */

.create-article-form {
  margin-bottom: 24px;
  padding: 24px;
  background: #F3F5F7;
  border-radius: 24px;
  border: none;
}

.article-create-header {
  margin-bottom: 8px;
  position: relative;
  text-align: center;
}

.article-create-header > a{
  position: absolute;
  right: 0;
  top: 0;
}

.create-article-form-content {
  display: flex;
  justify-content: space-between;
}

.create-article-form-content > .form-fieldset {
  padding: 0;
  margin: 0;
  background: transparent;
  border-radius: 0;
  border: none;
  flex: 1;
}

.create-article-form-content > .avatar {
  margin-right: 16px;
}

.create-article-form .form-fieldset .placeholder-input {
  width: 100%;
  padding: 14px 16px;;
  border: 1px solid #C2CCD6;
  border-radius: 24px;
  background-color: transparent;
  color: #A5A5A5;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.create-article-form .form-fieldset .textbox > textarea {
  width: 100%;
  padding: 14px 16px;;
  border: 1px solid #C2CCD6;
  border-radius: 24px;
  background-color: transparent;
  color: #A5A5A5;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.create-article-form .action-buttons {
  display: flex;
  align-items: center;
  margin-top: 16px;
}

.create-article-form .action-buttons .upload-image-button {
  background-color: transparent;
  border: none;
  padding: 0;
  margin-right: 16px;
  cursor: pointer;
}

.create-article-form .action-buttons .add-link-button {
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
}

.create-article-form .images-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
  box-sizing: border-box;
}

.images-list > div {
  flex: 1 1;
  margin-right: 8px;
  margin-bottom: 8px;
  min-width: 25%;
  box-sizing: border-box;
}

.images-list > div > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.images-list .image-wrap {
  position: relative;
}

.images-list .image-wrap > button {
  background-color: transparent;
  border: none;
  padding: 0;
  position: absolute;
  top: 8px;
  right: 8px;
}

.form-fieldset-links {
  padding: 0;
  margin: 16px 0 0 0;
  border: none;
  background: transparent;
}

.form-fieldset-links > li {
  margin-bottom: 16px;
}

.form-fieldset-links > li > div {
  padding: 0;
  position: relative;
}

.form-fieldset-links > li > div > button {
  padding: 0;
  border: none;
  position: absolute;
  top: -6px;
  right: 10px;
}

.form-fieldset-links input {
  padding: 12px;
  border: 1px solid #C2CCD6;
  border-radius: 24px;
  color: #A5A5A5;
  background: transparent;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

.form-fieldset-links label {
  padding: 0 16px;
  margin-bottom: 4px;
}

.create-article-form .article-categories {
  padding: 16px 0;
}

.article-categories-dropdown > div:first-of-type {
  border-radius: 24px;
  border-color: #C2CCD6;
  background: transparent;
  height: auto;
}

.create-article-form .submit-form {
  display: flex;
  justify-content: flex-end;
  padding: 0;
}

.create-article-form .submit-form button[disabled] {
 opacity: 0.6;
}

.article-card {
  width: 100%;
  height: 100%;
  text-align: left;
  cursor: pointer;
}

.article-card-content {
  margin-bottom: 24px;
  padding: 24px;
  border: 1px solid #C2CCD6;
  border-radius: 24px;
  text-align: left;
  position: relative;
}

.article-card-content .button {
  position: absolute;
  right: 24px;
  border: none;
  padding: 0;
}

.article-card-content .button.edit-btn {
  right: 56px;
}

.article-card-content p {
  padding: 16px 0;
}

.article-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.article-header-info {
  flex: 1;
  margin-left: 8px;
  display: flex;
  flex-direction: column;
}

.article-header-info > span {
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #222222;
}

.article-images {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
}

.article-images > div {
  flex: 1 1;
  margin-right: 8px;
  margin-bottom: 8px;
  min-width: 25%;
  box-sizing: border-box;
}

.article-images > div > img {
  margin: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.article-footer {
  display: flex;
  align-items: baseline;
}

.unauthorized-content {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}

.articles-filters {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}

.articles-filters hr {
  flex: 1;
  height: 2px;
  background-color: #C2CCD6;
  color: #C2CCD6;
  border-color: #C2CCD6;
  border-top: 1px solid #C2CCD6;
  margin-right: 8px;
}

.articles-filters {
  display: flex;
  align-items: center;
  color: #222222;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

.articles-filters-dropdown {
  display: flex;
  align-items: center;
}

.articles-filters .dropdown > .Dropdown-control {
  border: none;
  cursor: pointer;
}

.articles-filters .dropdown > .Dropdown-control:hover {
  box-shadow: none;
}

.articles-filters .dropdown .Dropdown-menu {
  width: 150px;
}

.upload-file {
  display: flex;
  justify-content: center;
  width: 100%;
}

.upload-file-input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.upload-file-label {
  width: 180px;
  height: 50px;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  display: block;
  font: 14px/50px Tahoma;
  transition: all 0.18s ease-in-out;
  border: 1px solid #333;
  color: #333;
}

.upload-file-label:hover {
  color: white;
  background: #333;
}

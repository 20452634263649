.buttons-block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 12px;

}

.buttons-block button:first-child {
  margin-bottom: 12px;
}
